// import React, { useState, useEffect, useRef } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import ButtonLink from './ButtonLink';
// import reactSyntaxHighlighter from 'react-syntax-highlighter';


const Header = () => {
  // const [isOpen, setIsOpen] = useState(false);
  // const dropdownRef = useRef(null);

  // const toggleDropdown = () => {
  //   setIsOpen(!isOpen);
  // };

  // const handleClickOutside = (event) => {
  //   if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //     setIsOpen(false);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, []);

  return (
    <div className="teacher-container">
      <div className="teacher-image">
      <Link to="/">
        <img src="/logo.png" alt="AIALA Logo" className="logo" />
      </Link>
      {/* <Link to="/"><h1>AIALA</h1></Link> */}
        
      </div>
      {/* <div className="teacher-right-section"> */}
      <div>
        <ul className="teacher-header-menu">
          {/* <li className="teacher-header-menu-item"><Link to="/">Home</Link></li> */}
          <li className="teacher-header-menu-item" >
            <ButtonLink url="/login" text="Log In" className='btn_link btn_dark_bg'></ButtonLink>
          </li>
          <li className="teacher-header-menu-item" >
            <ButtonLink url='/register' text="Sign Up" className='btn_link transparent'></ButtonLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
