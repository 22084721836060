// eslint-disable-next-line jsx-a11y/anchor-is-valid
import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom"; 
import "./StudentHeader.css";
import { FaUserCircle, FaBars } from "react-icons/fa";

const StudentHeader = () => {
  const navigate = useNavigate(); 
  const handleLogout = (event) => {
    event.preventDefault(); 
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("usertype");
    localStorage.removeItem("userData");
    localStorage.removeItem("chatSessionId");
    localStorage.removeItem("activities");
    localStorage.removeItem("assessment_id");
    localStorage.removeItem("StudentsessionHistory");
    localStorage.removeItem("StudentcourseSessionId");
    localStorage.removeItem("SessionID_apgen");
    localStorage.removeItem("AssesmentMessage");
    localStorage.removeItem("assgenID");
    localStorage.removeItem("lpgenID");
    localStorage.removeItem("encryptedFormData");
    localStorage.removeItem("StudentSessionId");
    localStorage.removeItem("StudentchatSessionId");
    localStorage.removeItem("assessmentData");
    
    // Loop through all localStorage items
    for (let i = localStorage.length - 1; i >= 0; i--) {
      const key = localStorage.key(i);
      if (key.startsWith("messages_")) {
        localStorage.removeItem(key);
      }
      if (key.startsWith("Studentmessages_")) {
        localStorage.removeItem(key);
      }
    }
  
    localStorage.removeItem("sessionHistory");
    localStorage.removeItem("sessionID");
    navigate("/"); 
  };
  
  const [selectedMenu, setSelectedMenu] = useState("Dashboard");
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [accountDropdownVisible, setAccountDropdownVisible] = useState(false);
  const searchRef = useRef(null);

  const handleMenuClick = (menu) => {
    setSelectedMenu(menu);
  };
  // Handle clicking outside to collapse search
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchRef]);

  return (
    <div className="student-container">
      <div className="student-image">
        <img
          src="/logo.png"
          alt="AIALA Logo"
          className="logo"
          onClick={() => navigate("/")}
        />
      </div>
      <FaBars className="hamburger" onClick={() => setMobileMenuOpen(!mobileMenuOpen)} />
      <div className="student-right-section">
      <ul className={`student-header-menu ${mobileMenuOpen ? "open" : ""}`}>
          {["Dashboard", "Assessments", "Study Buddy", "LearnMate", "Course"].map((menu) => {
            switch (menu) {
              case "Dashboard":
                return (
                  <li
                    key={menu}
                    className={`student-header-menu-item ${
                      selectedMenu === menu ? "selected" : ""
                    }`}
                    onClick={() => handleMenuClick(menu)}
                  >
                    <Link to="/StudentHome">{menu}</Link>{" "}
                    {/* Linking to StudentHome.js */}
                  </li>
                );
                case "Study Buddy":
                return (
                  <li
                    key={menu}
                    className={`student-header-menu-item ${
                      selectedMenu === menu ? "selected" : ""
                    }`}
                    onClick={() => handleMenuClick(menu)}
                  >
                    <Link to="/student-study">{menu}</Link>{" "}
                    {/* Linking to StudentHome.js */}
                  </li>
                );
                case "LearnMate":
                return (
                  <li
                    key={menu}
                    className={`student-header-menu-item ${
                      selectedMenu === menu ? "selected" : ""
                    }`}
                    onClick={() => handleMenuClick(menu)}
                  >
                    <Link to="/student-chatbot">{menu}</Link>{" "}
                    {/* Linking to StudentHome.js */}
                  </li>
                );
                case "Assessments":
                  return (
                    <li
                      key={menu}
                      className={`student-header-menu-item ${
                        selectedMenu === menu ? "selected" : ""
                      }`}
                      onClick={() => setSelectedMenu(menu)}
                    >
                      {menu}
                      <div className="student-dropdown-content">
                        <Link to="/">Mock Test</Link>
                        <Link to="/ViewAssessment">Assessment</Link>
                        <Link to="/view-Reports">Reports</Link>
                        
                        
                      </div>
                    </li>
                  );

                  case "Course":
                  return (
                    <li
                      key={menu}
                      className={`student-header-menu-item ${
                        selectedMenu === menu ? "selected" : ""
                      }`}
                      onClick={() => setSelectedMenu(menu)}
                    >
                      {menu}
                      <div className="student-dropdown-content">
                        <Link to="/shortCourse">View Courses</Link>
                        <Link to="/GenerateCourse">Generate Course</Link>
                      </div>
                    </li>
                  );
              default:
                return null; 
            }
          })}
        </ul>
        <div className="student-search-and-account">
          <div
            className="student-account-dropdown"
            onClick={() => setAccountDropdownVisible(!accountDropdownVisible)}
          >
            <FaUserCircle className="_header_icon" /> Accounts
            {accountDropdownVisible && (
              <div className="student-dropdown-content">
                <li>
                  <Link to="/account">My Account</Link>
                </li>
                {/* eslint-disable-next-line */}
                <a onClick={handleLogout}>Logout</a>
                {/* <button className="link-style" onClick={handleLogout}>Logout</button> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentHeader;
