// ButtonLink.js

import React from 'react';
import PropTypes from 'prop-types';
import './ButtonLink.css';


const ButtonLink = ({ url, text, className }) => {
  const handleClick = () => {
    window.location.href = url; // Navigate to the URL when clicked
  };

  return (
    <button className={className} onClick={handleClick}>
      {text}
    </button>
  );
};

ButtonLink.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default ButtonLink;
