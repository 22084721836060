// ButtonLink.js

import React from "react";
import PropTypes from "prop-types";
import "./LessonCard.css";
import CustomButton from "./CustomButton";

const LessonCard = ({ index, title, lesson, onclickfn }) => {
  const cardClick = () => {
    onclickfn(index);
  };
  return (
    <div className="lesson-card">
      <div className="lesson-card-content">
        <h3 className="lesson-card-title">{title}</h3>
        <p className="lesson-card-description">{lesson.chapter}</p>
        <CustomButton
          text="View Lesson"
          onClickfn={cardClick}
          className="custom_button transparent"
        ></CustomButton>
      </div>
      <img src="/logo.jpeg" alt="Math Basics" />
    </div>
  );
};

LessonCard.propTypes = {
  lesson: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default LessonCard;
