import React, { useState, useEffect } from 'react';
import { Settings, Calendar, Star, ChevronLeft, ChevronRight, Clock, School, User, CreditCard } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '../../components/ui/Dialog';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../components/ui/Select';
import { Button } from '../../components/ui/Button';
import { Card, CardContent } from '../../components/ui/card';
import CryptoJS from 'crypto-js';
import { getAssesmentResults } from '../../api';
import { getPendingAssessments } from '../../assesment_api';
import { getEvents } from '../../event_api';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import './StudentHome.css'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const generateTimeSlots = () => {
    const slots = [];
    for (let hour = 9; hour <= 17; hour++) {
        const timeString = `${hour.toString().padStart(2, '0')}:00`;
        slots.push(timeString);
    }
    return slots;
};

const LearningDashboard = () => {
    const [time, setTime] = useState(new Date());
    const [userData, setUserData] = useState({ firstname: '', username: '', grade: '' });
    const [currentSubjectIndex, setCurrentSubjectIndex] = useState(0);
    const [courseStats, setCourseStats] = useState({
        upcoming: 0,
        completion: { value: 0, percentage: "0%" },
        credits: 0,
        subjects: []
    });

    const decryptData = (data) => {
        const secretKey = process.env.REACT_APP_SECRET_KEY;
        try {
            const bytes = CryptoJS.AES.decrypt(data, secretKey);
            const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
            return JSON.parse(decryptedData);
        } catch (error) {
            console.error("Decryption failed:", error);
            return null;
        }
    };

    useEffect(() => {
        const fetchUserData = async () => {
            const storedData = localStorage.getItem('userData');
            if (storedData) {
                const decryptedData = decryptData(storedData);
                console.log("decrypted data:", decryptedData)
                if (decryptedData?.userData?.firstname) {
                    const firstname = decryptedData.userData.firstname;
                    const username = decryptedData.username;
                    const grade = decryptedData.classes;
                    const capitalizedFirstname = firstname.charAt(0).toUpperCase() + firstname.slice(1).toLowerCase();
                    setUserData({
                        firstname: capitalizedFirstname,
                        username: username,
                        grade: grade
                    });
                }
            }
        };

        fetchUserData();
    }, []);
    const [events, setEvents] = useState([]);

    useEffect(() => {
        const fetchEvents = async () => {
            if (userData.username && userData.grade) {
                const eventDetails = await getEvents(userData.username, userData.grade);
                setEvents(eventDetails);
            }
        };

        fetchEvents();
    }, [userData.username, userData.grade]);

    useEffect(() => {
        const fetchAssessmentData = async () => {
            if (userData.username) {
                const cacheKey = `assessmentData`;
                const cachedData = localStorage.getItem(cacheKey);
                let assessmentData;

                if (cachedData) {
                    const { data, timestamp } = JSON.parse(cachedData);
                    const dataAge = (new Date().getTime() - timestamp) / (1000 * 60); // Age in minutes
                    if (dataAge < 30) { // Cache valid for 30 minutes
                        assessmentData = data;
                    }
                }

                if (!assessmentData) {
                    const results = await getAssesmentResults(userData.username);
                    const pendingAssessments = await getPendingAssessments(userData.username);
                    assessmentData = {
                        upcoming: pendingAssessments.length,
                        completion: {
                            value: results.length,
                            percentage: `${(results.length / 10 * 100).toFixed(0)}%`
                        },
                        credits: results.reduce((acc, cur) => acc + cur.credits, 0),
                        subjects: results
                    };
                    localStorage.setItem(cacheKey, JSON.stringify({
                        data: assessmentData,
                        timestamp: new Date().getTime()
                    }));
                }

                setCourseStats(assessmentData);
            }
        };

        fetchAssessmentData();
    }, [userData.username]);

    const getGreeting = () => {
        const hours = time.getHours();
        return `Good ${hours < 12 ? 'Morning' : hours < 18 ? 'Afternoon' : 'Evening'}, ${userData.firstname}!`;
    };

    const nextSubject = () => {
        setCurrentSubjectIndex((prev) =>
            prev === courseStats.subjects.length - 1 ? 0 : prev + 1
        );
    };

    const prevSubject = () => {
        setCurrentSubjectIndex((prev) =>
            prev === 0 ? courseStats.subjects.length - 1 : prev - 1
        );
    };
    
    const EventsCarousel = ({ events }) => {
        const [currentIndex, setCurrentIndex] = useState(0);
        const [isModalOpen, setIsModalOpen] = useState(false);
        const [selectedTime, setSelectedTime] = useState('09:00');
        const [registrationDetails, setRegistrationDetails] = useState(null);
        const timeSlots = generateTimeSlots();
        
        useEffect(() => {
            const sortedEvents = events.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
            setCurrentIndex(0);
        }, [events]);
        
        useEffect(() => {
            const interval = setInterval(() => {
                setCurrentIndex(prevIndex => 
                    prevIndex === events.length - 1 ? 0 : prevIndex + 1
                );
            }, 5000);
        
            return () => clearInterval(interval);
        }, [events]);
        
        
        const handleRegister = (event) => {
            // Check if userData exists and has required properties
            if (!userData || !userData.firstname || !userData.grade) {
                console.error('User data is missing required properties:', userData);
                return;
            }
            setRegistrationDetails({
                
                name: 'Prem',
                school: 'Samskar',
                grade: '10',
                eventName: event.title,
                registrationType: event.registration_type,
                registrationFee: event.registration_type === 'Paid' ? event.registration_fee : 0
            });
            setIsModalOpen(true);
        };
    
        const handleConfirm = () => {
            // Handle registration confirmation
            if (registrationDetails.registrationType === 'Paid') {
                // Implement payment processing here
                console.log('Processing payment for:', registrationDetails);
            } else {
                console.log('Confirming free registration:', registrationDetails);
            }
            setIsModalOpen(false);
        };
    
        if (!events.length) return (
            <div className="empty-events">
                <p>No upcoming events.</p>
            </div>
        );
        
        const currentEvent = events[currentIndex];
        
        return (
            <div className="events-section">
                <button 
                    className="carousel-nav-button left"
                    onClick={() => setCurrentIndex(prev => prev === 0 ? events.length - 1 : prev - 1)}
                >
                    <ChevronLeft className="nav-icon" />
                </button>
                
                <div className="event-card-container">
                    <div className="event-card">
                        <div className="event-card-content">
                            <h3 className="event-title">{currentEvent.title}</h3>
                            <p className="event-description">{currentEvent.description}</p>
                            <div className="event-details">
                                <div className="event-info">
                                    <Calendar className="event-icon" />
                                    <span>{new Date(currentEvent.start_time).toLocaleDateString()}</span>
                                </div>
                                {currentEvent.prizes && (
                                    <div className="event-info">
                                        <Star className="event-icon" />
                                        <span>{currentEvent.prizes}</span>
                                    </div>
                                )}
                                <div className="event-info registration-type">
                                    <CreditCard className="event-icon" />
                                    <span>{currentEvent.registration_type}</span>
                                </div>
                            </div>
                            <Button 
                                className="register-button"
                                onClick={() => handleRegister(currentEvent)}
                            >
                                Register Now
                            </Button>
                        </div>
                    </div>
                </div>
    
                <button 
                    className="carousel-nav-button right"
                    onClick={() => setCurrentIndex(prev => prev === events.length - 1 ? 0 : prev + 1)}
                >
                    <ChevronRight className="nav-icon" />
                </button>
    
                <div className="event-indicators">
                    {events.map((_, idx) => (
                        <button
                            key={idx}
                            className={`indicator ${idx === currentIndex ? 'active' : ''}`}
                            onClick={() => setCurrentIndex(idx)}
                        />
                    ))}
                </div>
    
                <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
                    <DialogContent className="registration-modal">
                        <DialogHeader>
                            <DialogTitle>Event Registration</DialogTitle>
                        </DialogHeader>
                        
                        {registrationDetails && (
                            <div className="registration-form">
                                <div className="form-group">
                                    <User className="form-icon" />
                                    <div className="form-field">
                                        <label>Name</label>
                                        <input 
                                            type="text" 
                                            value={registrationDetails.name} 
                                            disabled 
                                            className="input-field"
                                        />
                                    </div>
                                </div>
    
                                <div className="form-group">
                                    <School className="form-icon" />
                                    <div className="form-field">
                                        <label>School & Grade</label>
                                        <input 
                                            type="text" 
                                            value={`${registrationDetails.school} - Grade ${registrationDetails.grade}`} 
                                            disabled 
                                            className="input-field"
                                        />
                                    </div>
                                </div>
    
                                <div className="form-group">
                                    <Clock className="form-icon" />
                                    <div className="form-field">
                                        <label>Preferred Time</label>
                                        <Select value={selectedTime} onValueChange={setSelectedTime}>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select time" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                {timeSlots.map(time => (
                                                    <SelectItem key={time} value={time}>
                                                        {time}
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </div>
                                </div>
    
                                {registrationDetails.registrationType === 'Paid' && (
                                    <div className="form-group">
                                        <CreditCard className="form-icon" />
                                        <div className="form-field">
                                            <label>Registration Fee</label>
                                            <input 
                                                type="text" 
                                                value={`$${registrationDetails.registrationFee}`} 
                                                disabled 
                                                className="input-field"
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
    
                        <DialogFooter>
                            {registrationDetails?.registrationType === 'Paid' ? (
                                <div className="button-group">
                                    <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
                                        Cancel
                                    </Button>
                                    <Button onClick={handleConfirm}>
                                        Confirm & Pay
                                    </Button>
                                </div>
                            ) : (
                                <Button onClick={handleConfirm}>
                                    Confirm Registration
                                </Button>
                            )}
                        </DialogFooter>
                    </DialogContent>
                </Dialog>
            </div>
        );
    };

    
    const SubjectProgressCard = () => {
        const subject = courseStats.subjects[currentSubjectIndex];
        if (!subject) return null;
        // Helper function to normalize topic score data
        const normalizeTopicScores = (topicScore) => {
            if (Array.isArray(topicScore)) {
                // Handle array of objects (like in BIOLOGY)
                return topicScore.reduce((acc, group) => {
                    return [...acc, ...Object.entries(group).map(([topic, scores]) => ({
                        topic,
                        percentage: (scores.correct / scores.total) * 100,
                        totalMarks: scores.total,
                        correctAnswers: scores.correct
                    }))];
                }, []);
            } else {
                // Handle single object (like in MATHS)
                return Object.entries(topicScore).map(([topic, scores]) => ({
                    topic,
                    percentage: (scores.correct / scores.total) * 100,
                    totalMarks: scores.total,
                    correctAnswers: scores.correct
                }));
            }
        };
        const topicData = normalizeTopicScores(subject.details.topic_score);


        const chartData = {
            labels: topicData.map(item => item.topic),
            datasets: [
                {
                    label: 'Score Percentage',
                    data: topicData.map(item => item.percentage),
                    backgroundColor: '#8b5cf6',
                    borderColor: '#7c3aed',
                    borderWidth: 1,
                }
            ]
        };

        const chartOptions = {
            indexAxis: 'y',
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    callbacks: {
                        label: (context) => {
                            const data = topicData[context.dataIndex];
                            return `${data.correctAnswers}/${data.totalMarks} (${data.percentage.toFixed(1)}%)`;
                        }
                    }
                }
            },
            scales: {
                x: {
                    beginAtZero: true,
                    max: 100,
                    title: {
                        display: true,
                        text: 'Score Percentage'
                    }
                },
                y: {
                    ticks: {
                        callback: function (value) {
                            const label = this.getLabelForValue(value);
                            return label.length > 20 ? label.substr(0, 17) + '...' : label;
                        }
                    }
                }
            }
        };

        return (
            <Card className="subject-progress-card">
                <CardContent>
                    <div className="subject-header">
                        <h3 className="subject-title">{subject.subject}</h3>
                        <div className="subject-score">
                            <span className="score-percentage">
                                ({((subject.total_score / subject.total_marks) * 100).toFixed(1)}%)
                            </span>
                        </div>
                    </div>

                    <div className="topic-chart">
                        <Bar data={chartData} options={chartOptions} />
                    </div>

                    <div className="navigation-controls">
                        <button
                            onClick={prevSubject}
                            className="nav-button"
                            disabled={courseStats.subjects.length <= 1}
                        >
                            <ChevronLeft className="nav-icon" />
                        </button>
                        <span className="subject-counter">
                            {currentSubjectIndex + 1} / {courseStats.subjects.length}
                        </span>
                        <button
                            onClick={nextSubject}
                            className="nav-button"
                            disabled={courseStats.subjects.length <= 1}
                        >
                            <ChevronRight className="nav-icon" />
                        </button>
                    </div>
                </CardContent>
            </Card>
        );
    };

    const SimpleStatCard = ({ title, value, trend, icon: Icon, iconBgColor }) => (
        <Card className="stat-card">
            <CardContent>
                <div className="stat-header">
                    <h3 className="stat-title">{title}</h3>
                    <div className="stat-value-container">
                        <span className="stat-value">{value}</span>
                        <span className={`trend-indicator ${trend === '▲' ? 'trend-up' : 'trend-down'}`}>
                            {trend}
                        </span>
                    </div>
                </div>
                <div className={`stat-icon-container ${iconBgColor}`}>
                    <Icon className="stat-icon" />
                    <div className="stat-description">
                        {title === "Pending Assessments"
                            ? "Upcoming tests and assignments"
                            : "Total credits earned"}
                    </div>
                </div>
            </CardContent>
        </Card>
    );

    return (
        <div className="dashboard-container">
            <div className="dashboard-header">
                <h1 className="greeting">{getGreeting()}</h1>
                <button className="settings-button">
                    <Settings className="settings-icon" />
                </button>
            </div>
            <div className="Event-grid">
                <div className="main-stats">
                    <p className="stat-label">Upcoming Events</p>
                    <EventsCarousel events={events} />
                </div>
            </div>
            <div className="stats-grid">
                <div className="main-stats">
                    <p className="stat-label">Assessment Details</p>
                    <SubjectProgressCard />
                </div>
                <div className="side-stats">
                    <SimpleStatCard
                        title="Pending Assessments"
                        value={courseStats.upcoming}
                        trend="▲"
                        icon={Calendar}
                        iconBgColor="icon-bg-orange"
                    />
                    <SimpleStatCard className="side-stats-right"
                        title="Total Credits"
                        value={courseStats.subjects[currentSubjectIndex]
                            ? `${courseStats.subjects[currentSubjectIndex].subject}: ${courseStats.subjects[currentSubjectIndex].total_score} / ${courseStats.subjects[currentSubjectIndex].total_marks}`
                            : "No data"}
                        trend="▲"
                        icon={Star}
                        iconBgColor="icon-bg-green"
                    />
                </div>
            </div>
            <div className="Goals-grid">
                <div className="main-stats">
                    <p className="stat-label">Goals And Suggestions? </p>

                </div>
            </div>
            <div className="Event-grid">
                <div className="main-stats">
                    <p className="stat-label">Where Are We Right Now ? </p>

                </div>
            </div>
            <div className="Event-grid">
                <div className="main-stats">
                    <p className="stat-label">Rewards and Achievements </p>
                </div>
            </div>
        </div>
    );
};

export default LearningDashboard;