import React from "react";
import "./LoadingAnimation.css"; // Import the CSS specifically for the loading component

const Loading = () => {
  return (
    <div className="loading-overlay">
      <img src="/loading.gif" alt="Loading" className="loading-image" />
    </div>
  );
};

export default Loading;
