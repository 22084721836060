// Select.js
import React, { useState, useRef, useEffect } from 'react';
import './Select.css';

export const Select = ({ value, onValueChange, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);
  const selectRef = useRef(null);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelect = (value) => {
    console.log('handleSelect called with value:', value);
    setSelectedValue(value);
    if (typeof onValueChange === 'function') {
      onValueChange(value);
    } else {
      console.error('onValueChange is not a function:', onValueChange);
    }
    setIsOpen(false);
  };

  return React.createElement('div', {
    className: 'select-container',
    ref: selectRef
  }, [
    React.createElement('div', {
      className: `select-trigger ${isOpen ? 'open' : ''}`,
      onClick: () => setIsOpen(!isOpen),
      key: 'trigger'
    }, [
      React.createElement('span', {
        key: 'value',
        className: 'select-value'
      }, selectedValue || 'Select...'),
      React.createElement('span', {
        key: 'arrow',
        className: `select-arrow ${isOpen ? 'open' : ''}`
      }, '▼')
    ]),
    isOpen && React.createElement('div', {
      className: 'select-content',
      key: 'content'
    }, React.Children.map(children, child => 
      React.cloneElement(child, {
        onSelect: handleSelect,
        isSelected: child.props.value === selectedValue
      })
    ))
  ]);
};

export const SelectTrigger = ({ children }) => {
  return React.createElement('div', {
    className: 'select-trigger'
  }, children);
};

export const SelectContent = ({ children }) => {
  return React.createElement('div', {
    className: 'select-content'
  }, children);
};

export const SelectItem = ({ value, children, onSelect, isSelected }) => {
  return React.createElement('div', {
    className: `select-item ${isSelected ? 'selected' : ''}`,
    onClick: () => {
      console.log('SelectItem onClick with onSelect:', onSelect);
      if (typeof onSelect === 'function') {
        onSelect(value);
      } else {
        console.error('onSelect is not a function:', onSelect);
      }
    }
  }, children);
};
export const SelectValue = ({ children }) => {
  return React.createElement('span', {
    className: 'select-value'
  }, children);
};