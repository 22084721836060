import React, { useState, useEffect } from 'react';
import { getAllTeacherAssessments } from "../api";
import CryptoJS from "crypto-js";

const decryptData = (encryptedData) => {
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error("Decryption failed:", error);
    return null;
  }
};

const AssessmentCard = ({ subject, chapter, totalMarks, timeLimit, expiry }) => (
  <div className="assessment-card">
    <h3 className="card-title">{subject} Test</h3>
    <p className="card-chapter">Chapter: {chapter}</p>
    <p className="card-details">
      Total Marks: {totalMarks}, Time Limit: {timeLimit} mins, Expiry: {expiry}
    </p>
    <button className="start-button">Start Assessment</button>
  </div>
);

const AssessmentCardSection = () => {
  const [assessments, setAssessments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAssessments = async () => {
      try {
        const encryptedUserData = localStorage.getItem("userData");
        const userDetails = encryptedUserData ? decryptData(encryptedUserData) : {};
        
        if (!userDetails.username) {
          throw new Error("User details not found");
        }

        const response = await getAllTeacherAssessments(userDetails.username);
        setAssessments(response);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching assessments:", err);
        setError("Failed to load assessments. Please try again later.");
        setLoading(false);
      }
    };

    fetchAssessments();
  }, []);

  if (loading) {
    return <div>Loading assessments...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="assessment-section">
      <h2 className="section-title">Available Assessments</h2>
      <div className="card-grid">
        {assessments.map((assessment, index) => (
          <AssessmentCard 
            key={index} 
            subject={assessment.subject}
            chapter={assessment.chapter}
            totalMarks={assessment.total_marks}
            timeLimit={assessment.time_limit}
            expiry={assessment.validity}
          />
        ))}
      </div>
      <style jsx>{`
        .assessment-section {
          max-width: 1000px;
          margin: 0 auto;
          padding: 20px;
        }
        .section-title {
          font-size: 24px;
          margin-bottom: 20px;
        }
        .card-grid {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
          gap: 20px;
        }
        .assessment-card {
          border: 1px solid #e0e0e0;
          border-radius: 8px;
          padding: 16px;
          background-color: #fff;
        }
        .card-title {
          font-size: 18px;
          margin: 0 0 10px;
        }
        .card-chapter {
          color: #666;
          margin: 0 0 10px;
        }
        .card-details {
          font-size: 14px;
          margin: 0 0 15px;
        }
        .start-button {
          background-color: #6750a4;
          color: white;
          border: none;
          border-radius: 20px;
          padding: 8px 16px;
          cursor: pointer;
          font-size: 14px;
        }
        .start-button:hover {
          background-color: #553c9a;
        }
      `}</style>
    </div>
  );
};

export default AssessmentCardSection;