// src/components/SuccessModal.js

import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import './SuccessModal.css';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
};

const SuccessModal = ({ isOpen, onClose }) => {
  const handleRedirect = () => {
    onClose();
    window.location.href = '/';
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Registration Successful
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Your account has been created successfully.
        </Typography>
        <Button onClick={handleRedirect} variant="contained" color="primary" sx={{ mt: 2 }}>
          Okay
        </Button>
      </Box>
    </Modal>
  );
};

export default SuccessModal;
