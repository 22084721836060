import React, { useState, useEffect, useCallback } from "react";
import "./ChatBot.css";
import { sendMessage } from "../api";
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from "react-icons/io";
import { BiMessageSquareAdd } from "react-icons/bi";
import { IoSend } from "react-icons/io5";
import ChatBotChat from "../components/ChatBotChat";
import CryptoJS from 'crypto-js';

const encryptData = (data) => {
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};

const decryptData = (encryptedData) => {
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error("Decryption failed:", error);
    return null;
  }
};

const generateSessionId = () => `${new Date().getTime()}-${Math.random().toString(36).substr(2, 9)}`;

const generateSessionName = (lastMessage) => {
  return lastMessage ? `${lastMessage.substring(0, 20)}${lastMessage.length > 20 ? '...' : ''}` : "New Chat Session";
};

const ChatBot = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [sessionId, setSessionId] = useState(() => decryptData(localStorage.getItem("chatSessionId")) || generateSessionId());
  const [userDetails, setUserDetails] = useState({ firstname: "", email: "", username: "" });
  const [sessionHistory, setSessionHistory] = useState(() => decryptData(localStorage.getItem("sessionHistory")) || []);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [sessionID] = useState(() => localStorage.getItem("sessionID")) || [];
  // console.log(sessionID)


  const saveMessagesToLocalStorage = useCallback((sessionId, messages) => {
    localStorage.setItem(`messages_${sessionId}`, encryptData(messages));
  }, []);

  const loadMessagesFromLocalStorage = useCallback((sessionId) => {
    return decryptData(localStorage.getItem(`messages_${sessionId}`)) || [];
  }, []);
  

  useEffect(() => {
    localStorage.setItem("chatSessionId", encryptData(sessionId));
    const storedData = decryptData(localStorage.getItem("userData"));
    // console.log(storedData)
    if (storedData) {
      setUserDetails({
        firstname: storedData.firstname,
        email: storedData.email,
        username: storedData.username,
      });
    }
  }, [sessionId]);
  console.log(messages);

  useEffect(() => {
    const lastMessage = messages[messages.length - 1]?.message;
    const timestamp = new Date().toISOString();
    if (lastMessage && !sessionHistory.find(s => s.id === sessionId)) {
      const newSessionName = generateSessionName(lastMessage);
      const newHistory = [...sessionHistory, { id: sessionId, name: newSessionName, timestamp }];
      setSessionHistory(newHistory);
      localStorage.setItem("sessionHistory", encryptData(newHistory));
    }
  }, [messages, sessionHistory, sessionId]);

  useEffect(() => {
    setMessages(loadMessagesFromLocalStorage(sessionId));
  }, [sessionId, loadMessagesFromLocalStorage]);

  const handleNewMessageChange = (event) => setNewMessage(event.target.value);

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;
    const storedUserData = decryptData(localStorage.getItem("userData"));
    const messageData = {
      message: newMessage,
      sender: "psychology",
      timestamp: new Date().toLocaleTimeString(),
      firstname: storedUserData.userData.firstname || "Anonymous",
      usertype: storedUserData.usertype || "unknown",
      username: userDetails.username || "anonymous",
      email: storedUserData.userData.email || "no-email@example.com",
      sessionID: sessionID, 
      chatID: `messages_${sessionId}` 
    };
    setMessages(prev => [...prev, messageData]);
    setNewMessage("");


    try {
      const response = await sendMessage(messageData);
      if (!response.ok) {
        throw new Error("Failed to fetch from the server");
      }
      const responseData = await response.json();
      const botMessage = {
        message: responseData.message || "No message received",
        sender: "bot",
        timestamp: new Date().toLocaleTimeString(),
      };
      // Add only the bot's response here
      setMessages(prev => [...prev, botMessage]);
      saveMessagesToLocalStorage(sessionId, [...messages, messageData, botMessage]);
      // const newUpdatedMessages = [...updatedMessages, botMessage];
      // setMessages(newUpdatedMessages);
      // saveMessagesToLocalStorage(sessionId, newUpdatedMessages);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      handleSendMessage();
      event.preventDefault();
    }
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
    document.querySelector(".chat-container").classList.toggle("sidebar-closed");
  };

  const groupSessionsByDate = (sessions) => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const lastWeek = new Date(today);
    lastWeek.setDate(lastWeek.getDate() - 7);

    const groups = { today: [], yesterday: [], lastWeek: [], earlier: [] };

    sessions.forEach(session => {
        const sessionDate = new Date(session.timestamp);

        if (sessionDate >= today) {
            groups.today.push(session);
        } else if (sessionDate >= yesterday && sessionDate < today) {
            groups.yesterday.push(session);
        } else if (sessionDate >= lastWeek && sessionDate < yesterday) {
            groups.lastWeek.push(session);
        } else {
            groups.earlier.push(session);
        }
    });

    return groups;
  };

  const renderSession = (session) => {
    return (
        <div key={session.id} className={`session-card ${session.id === sessionId ? "active" : ""}`} onClick={() => handleSessionClick(session.id)}>
            <h3>{session.name}</h3>
            {/* <p>ID: {session.id.substring(0, 8)}</p> */}
        </div>
    );
  };

  const renderSessions = () => {
    const groupedSessions = groupSessionsByDate(sessionHistory);

    return (
        <div>
            {groupedSessions.today.length > 0 && (
                <div>
                    <h3>Today</h3>
                    {groupedSessions.today.map(session => renderSession(session))}
                </div>
            )}
            {groupedSessions.yesterday.length > 0 && (
                <div>
                    <h3>Yesterday</h3>
                    {groupedSessions.yesterday.map(session => renderSession(session))}
                </div>
            )}
            {groupedSessions.lastWeek.length > 0 && (
                <div>
                    <h3>Last 7 Days</h3>
                    {groupedSessions.lastWeek.map(session => renderSession(session))}
                </div>
            )}
            {groupedSessions.earlier.length > 0 && (
                <div>
                    <h3>Earlier</h3>
                    {groupedSessions.earlier.map(session => renderSession(session))}
                </div>
            )}
        </div>
    );
  };
  const handleNewSession = () => {
    const newSessionId = generateSessionId();
    const placeholderName = "Starting New Session";  
    const timestamp = new Date().toISOString();

    const newSession = { id: newSessionId, name: placeholderName, timestamp };
    const updatedHistory = [newSession, ...sessionHistory];  

    setSessionId(newSessionId);
    setSessionHistory(updatedHistory);
    localStorage.setItem("sessionHistory", encryptData(updatedHistory));
    localStorage.setItem("chatSessionId", encryptData(newSessionId));
    setMessages([]);
  };
  useEffect(() => {
    // Check the first session at index 0 if it has the placeholder name
    if (messages.length > 0 && sessionHistory.length > 0 && sessionHistory[0].name === "Starting New Session" && sessionHistory[0].id === sessionId) {
      const firstMessage = messages[0].message;  // Using the first message in the session
      const newName = `${firstMessage.substring(0, 20)}${firstMessage.length > 20 ? '...' : ''}`;
      const updatedHistory = sessionHistory.map(session => {
        if (session.id === sessionId) return { ...session, name: newName };
        return session;
      });
      setSessionHistory(updatedHistory);
      localStorage.setItem("sessionHistory", encryptData(updatedHistory));
    }
  }, [messages, sessionId, sessionHistory]);

  const handleSessionClick = (clickedSessionId) => {
    setSessionId(clickedSessionId);
    localStorage.setItem("chatSessionId", encryptData(clickedSessionId));
    const loadedMessages = loadMessagesFromLocalStorage(clickedSessionId);
    setMessages(loadedMessages);
  };

  return (
    <div className="chat-container">
      <div className="sidebar-section">
        
        <div className="side-toggle" onClick={toggleSidebar}>
          {sidebarOpen ? <IoIosArrowDropleftCircle /> : <IoIosArrowDroprightCircle />}
        </div>
        {sidebarOpen && (
          <div className="_chatsidebar">
            {/* <h2>Session History</h2> */}
            <div className="session-list">
              {renderSessions()}
            </div>
          </div>
        )}
      </div>
      <div className="chat">
        <div className="chat-title-container">
          <div className="chat-title">Expert Chat Assistant</div>
          <BiMessageSquareAdd className="add_session" onClick={handleNewSession} />
        </div>
        <div className="messages">
          <ChatBotChat chatHistory={messages} />
        </div>
        <div className="message-box">
          <input
            className="message-input"
            placeholder="Type a message..."
            value={newMessage}
            onChange={handleNewMessageChange}
            onKeyDown={handleKeyDown}
          />
          <IoSend className="message_submit" onClick={handleSendMessage} />
        </div>
      </div>
    </div>
  );
};

export default ChatBot;
