// Button.js
import React from 'react';
import './Button.css';

export const Button = ({ 
  children, 
  variant = 'default', // default, secondary, destructive, outline, ghost, link
  size = 'default', // default, sm, lg, icon
  className = '',
  disabled = false,
  onClick,
  type = 'button',
  ...props 
}) => {
  return React.createElement(
    'button',
    {
      className: `button ${variant} ${size} ${className}`,
      disabled: disabled,
      onClick: onClick,
      type: type,
      ...props
    },
    children
  );
};