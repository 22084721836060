// src/events_api.js

const API_URL = "https://aiala.troshi.in/api";

export const getEvents = async (username, grade) => {
    try {
      const response = await fetch(
        `${API_URL}/events/getEvents`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({ username, grade })  // Include username in the body of the POST request
        }
      );
      if (!response.ok) {
        throw new Error(`Failed to fetch grades: ${response.statusText}`);
      }
      const text = await response.text(); // Use .text() to handle non-JSON responses gracefully
      return text ? JSON.parse(text) : {}; // Only parse if text is not empty
    } catch (error) {
      console.error("Error fetching grades:", error);
      throw error;
    }
  };

  export const registerEvent = async (username) => {
    try {
      const response = await fetch(
        `${API_URL}/events/registerEvent`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({ username })  // Include username in the body of the POST request
        }
      );
      if (!response.ok) {
        throw new Error(`Failed to fetch grades: ${response.statusText}`);
      }
      const text = await response.text(); // Use .text() to handle non-JSON responses gracefully
      return text ? JSON.parse(text) : {}; // Only parse if text is not empty
    } catch (error) {
      console.error("Error fetching grades:", error);
      throw error;
    }
  };