import React from 'react';
// import './payment.css';

const PaymentPage = () => {
  const subscriptions = [
    {
      name: 'Basic Plan',
      price: '$10/month',
      features: ['Feature 1', 'Feature 2', 'Feature 3'],
    },
    {
      name: 'Standard Plan',
      price: '$20/month',
      features: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4'],
    },
    {
      name: 'Premium Plan',
      price: '$30/month',
      features: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4', 'Feature 5'],
    },
  ];

  return (
    <div className="payment-page">
      <h1>Subscription Plans</h1>
      <div className="card-container">
        {subscriptions.map((subscription, index) => (
          <div key={index} className="card">
            <h2>{subscription.name}</h2>
            <p className="price">{subscription.price}</p>
            <ul>
              {subscription.features.map((feature, idx) => (
                <li key={idx}>{feature}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PaymentPage;
