import React, { useState } from 'react';
import './Register.css';
import Button from '../components/Button';
import countryCodes from '../countryCodes';
import { registerUser } from '../api';
import LoadingAnimation from '../components/LoadingAnimation';
import SuccessModal from '../components/SuccessModal';

const Register = () => {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    username: '',
    email: '',
    phone: '',
    countryCode: 'IN',
    password: '',
    confirmPassword: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === 'email') {
      validateEmail(value);
    }

    if (name === 'phone') {
      validatePhone(value);
    }

    if (name === 'password' || name === 'confirmPassword') {
      validatePassword(formData.password, formData.confirmPassword, name, value);
    }
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!re.test(String(email).toLowerCase())) {
      setEmailError('Invalid email format');
    } else {
      setEmailError('');
    }
  };

  const validatePhone = (phone) => {
    const re = /^[0-9\b]+$/; // Allow only numbers
    if (!re.test(phone)) {
      setPhoneError('Phone number can only contain numbers');
    } else if (phone.length > 10) {
      setPhoneError('Phone number cannot be greater than 10 digits');
    } else {
      setPhoneError('');
    }
  };

  const validatePassword = (password, confirmPassword, fieldName, value) => {
    if (fieldName === 'password') {
      password = value;
    } else {
      confirmPassword = value;
    }

    const passwordRegex = /^(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/;
    if (!passwordRegex.test(password)) {
      setPasswordError('Password must be at least 8 characters long and include a special character');
    } else if (password !== confirmPassword) {
      setPasswordError('Passwords do not match');
    } else {
      setPasswordError('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (emailError || phoneError || passwordError) {
      setError('Please fix the errors before submitting');
      return;
    }
    setLoading(true);
    console.log('Submitting form with data:', formData);
    try {
      const result = await registerUser(formData);
      console.log('Result from backend:', result);
      if (result.msg) {
        setError(result.msg);
        setLoading(false);
      } else {
        setModalOpen(true);
        setError('');
        setLoading(false);
        // Redirect to the question-answer session page
        window.location.href = '/login';
      }
    } catch (error) {
      console.error('Error registering user:', error);
      setError('Registration failed');
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <LoadingAnimation />}
      <div className={`register-container ${loading ? 'loading' : ''}`}>
        <div className="register-box">
          <h2>Register</h2>
          {error && <p className="register-error">{error}</p>}
          <form onSubmit={handleSubmit}>
            <div className="register-form-row">
              <div className="register-input-group">
                <label htmlFor="firstname">First Name</label>
                <input type="text" id="firstname" name="firstname" required onChange={handleChange} />
              </div>
              <div className="register-input-group">
                <label htmlFor="lastname">Last Name</label>
                <input type="text" id="lastname" name="lastname" required onChange={handleChange} />
              </div>
            </div>
            <div className="register-form-row">
              <div className="register-input-group">
                <label htmlFor="username">Username</label>
                <input type="text" id="username" name="username" required onChange={handleChange} />
              </div>
              <div className="register-input-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  onChange={handleChange}
                  className={emailError ? 'invalid' : ''}
                />
                {emailError && <p className="register-error">{emailError}</p>}
              </div>
            </div>
            <div className="register-form-row">
              <div className="register-input-group">
                <label htmlFor="countryCode">Country Code</label>
                <select
                  id="countryCode"
                  name="countryCode"
                  value={formData.countryCode}
                  onChange={handleChange}
                  required
                >
                  {countryCodes.map((code) => (
                    <option key={code.code} value={code.code}>
                      {code.name} {code.display}
                    </option>
                  ))}
                </select>
              </div>
              <div className="register-input-group">
                <label htmlFor="phone">Phone Number</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  required
                  onChange={handleChange}
                  className={phoneError ? 'invalid' : ''}
                />
                {phoneError && <p className="register-error">{phoneError}</p>}
              </div>
            </div>
            <div className="register-form-row">
              <div className="register-input-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  required
                  onChange={handleChange}
                  className={passwordError ? 'invalid' : ''}
                />
              </div>
              <div className="register-input-group">
                <label htmlFor="confirmPassword">Confirm Password</label>
                <input
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  required
                  onChange={handleChange}
                  className={passwordError ? 'invalid' : ''}
                />
                {passwordError && <p className="register-error">{passwordError}</p>}
              </div>
            </div>
            <Button as="button" filled type="submit">Register</Button>
          </form>
        </div>
      </div>
      <SuccessModal isOpen={modalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
};

export default Register;
