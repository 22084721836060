// src/components/SaasHeader.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './SaasHeader.css';

const SaasHeader = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('usertype');
    localStorage.removeItem('username');
    localStorage.removeItem('userData');
    localStorage.removeItem('classes');
    localStorage.removeItem('board');
    localStorage.removeItem('subjects');
    navigate('/');
  };

  return (
    <header className="saas-header">
      <img src="/logo.jpeg" alt="AIALA Logo" className="logo" />
      <nav>
        <ul>
          <li><Link to="/saas/home">Dashboard</Link></li>
          <li><Link to="/schedules">Schedules</Link></li>
          <li><Link to="/LessonPlan">LessonPlan</Link></li>
          <li><Link to="/documents">Documents</Link></li>
          <li><Link to="/account">My Account</Link></li>
          <li><button onClick={handleLogout}>Logout</button></li>
        </ul>
      </nav>
    </header>
  );
};

export default SaasHeader;
