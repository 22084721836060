// eslint-disable-next-line
// eslint-disable import/no-anonymous-default-export 

import React, { useState } from 'react';
import './Home.css';

const AIALAHome = () => {
  const [isAnnual, setIsAnnual] = useState(false);
  const [formData, setFormData] = useState({ name: '', email: '', message1: '' });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.name && formData.email && formData.message1) {
      alert('Thank you for your message. We will get back to you soon!');
      setFormData({ name: '', email: '', message1: '' });
    } else {
      alert('Please fill in all fields.');
    }
  };

  const togglePricing = () => setIsAnnual(!isAnnual);

  return (
    <div className="home-wrapper">
      <section className="home-hero">
        <div className="home-container">
          <h1>Welcome to AIALA</h1>
          <p>The Smart Solution To Educate By Your Self with AI</p>
          <a href="#learn-more" className="home-btn-primary">Learn More</a>
        </div>
      </section>

      <main>
        <section id="about" className="home-about">
          <div className="home-container">
            <h2>About Us - Troshi</h2>
            <h3>Innovating for Tomorrow</h3>
            <div className="home-about-content">
              <div className="home-about-text">
                <p>At Troshi, we believe technology is the backbone of progress. Founded in 2023 by a team of visionary engineers and entrepreneurs, our mission is to streamline the process of studies through cutting-edge software solutions. Based in Srikakulam, India. Troshi has become a beacon of innovation, pushing the boundaries of artificial intelligence and machine learning to solve real-world problems.</p>
              </div>
              <div className="home-about-img">
                <img src="/path/to/image.jpg" alt="About AIALA" />
              </div>
            </div>
          </div>
        </section>

        <section id="services" className="home-services">
          <div className="home-container">
            <h2>How Can AIALA Be Used?</h2>
            <div className="home-service-grid">
              {[
                {
                  name: 'Educational Integration',
                  description: 'AIALA easily integrates with existing educational platforms, enhancing them with advanced AI tutoring capabilities.'
                },
                {
                  name: 'Personalized Tutoring',
                  description: 'Offering customized, AI-driven learning experiences tailored to individual student needs and learning styles.'
                },
                {
                  name: 'Multilingual Parent Support',
                  description: 'Provides comprehensive support in multiple Indian languages, bridging communication gaps for non-English speaking parents.'
                },
                {
                  name: 'Student Empowerment Tools',
                  description: 'Equips students with sophisticated tools and resources, fostering independence and boosting learning outcomes.'
                }
              ].map((service) => (
                <div key={service.name} className="home-service-card">
                  <img src="/path/to/service.jpg" alt={`${service.name}`} />
                  <h3>{service.name}</h3>
                  <p>{service.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="pricing" className="home-pricing">
          <div className="home-container">
            <h2>Pricing Plans</h2>
            <div className="pricing-toggle">
              <button className={`toggle-button ${!isAnnual ? 'active' : ''}`} onClick={togglePricing}>Monthly</button>
              <button className={`toggle-button ${isAnnual ? 'active' : ''}`} onClick={togglePricing}>Annually</button>
            </div>
            <div className="home-pricing-grid">
              {[
                { plan: 'Basic', monthly: '₹750', annual: '₹8100', features: ['Access to basic modules', 'Up to 5 hours of AI tutoring per month', 'Email support', 'chat Support'] },
                { plan: 'Premium', monthly: '₹2200', annual: '₹23760', features: ['All basic features', 'Unlimited AI tutoring', 'Priority email and chat support', 'Access to premium modules'] },
                { plan: 'Enterprise', monthly: '₹7400', annual: '₹79920', features: ['All premium features', 'Dedicated account manager', 'On-site training', 'Custom integration services'] }
              ].map(({ plan, monthly, annual, features }) => (
                <div key={plan} className="home-pricing-card">
                  <h3>{plan}</h3>
                  <p>{isAnnual ? `Annually: ${annual}` : `Monthly: ${monthly}`}</p>
                  <ul className="features-list">
                    {features.map(feature => <li key={feature}>{feature}</li>)}
                  </ul>
                  <button className="home-btn-primary">Choose {plan}</button>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="contact" className="home-contact">
          <div className="home-container">
            <h2>Contact Us</h2>
            <div className="home-contact-content">
              <div className="home-contact-info">
                <h3>Get in Touch</h3>
                <p>Reach us at our headquarters or through our contact channels.</p>
              </div>
              <form onSubmit={handleSubmit}>
                <input type="text" name="name" placeholder="Your Name" value={formData.name} onChange={handleInputChange} />
                <input type="email" name="email" placeholder="Your Email" value={formData.email} onChange={handleInputChange} />
                <textarea name="message1" placeholder="Your message" value={formData.message1} onChange={handleInputChange}></textarea>
                <button type="submit" className="home-btn-primary">Send Message</button>
              </form>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default AIALAHome;
