// ButtonLink.js

import React from "react";
import PropTypes from "prop-types";
import "./ChatBotchat.css";
import { FaRobot, FaUserCircle } from "react-icons/fa";
import ReactMarkdown from 'react-markdown';

const ChatBotChat = ({ chatHistory }) => {
  return generateBot(chatHistory);
};
const generateBot = (chat) => {
  return chat.map((e, index) => {
    return (
      <ChatMessage
        type={e.sender}
        message={e.message}
        timestamp={e.timestamp}
        key={index}
      ></ChatMessage>
    );
  });
};

const ChatMessage = ({ type, message, timestamp }) => {
  return (
    <div className={`chat-message ${type}`}>
      <div className="chat-msg">
        <div>{type === "bot" ? <FaRobot className="chat-icon" /> : ""}</div>
        <div className="message-content">
          {/* <span>{message}</span> */}
          <ReactMarkdown>{message}</ReactMarkdown>
        </div>
        <div>
          {type === "user" ? <FaUserCircle className="chat-icon" /> : ""}
        </div>
      </div>
      {timestamp ? <span className="chat_timestamp">{timestamp}</span> : ""}
    </div>
  );
};
ChatBotChat.propTypes = {
  chatHistory: PropTypes.array.isRequired,
};

export default ChatBotChat;
