import React from 'react';
import './TeacherHome.css';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { useNavigate } from 'react-router-dom';

// Register the required elements
ChartJS.register(ArcElement, Tooltip, Legend);

const data = {
  labels: ['Expert', 'Okay', 'Need Assistance', 'Bad'],
  datasets: [
    {
      label: 'Proficiency',
      data: [25, 25, 25, 25],
      backgroundColor: ['#6c63ff', '#3498db', '#f39c12', '#e74c3c'],
    },
  ],
};

const Dashboard = () => {
  const navigate = useNavigate();
  const handleAddStudentClick = () => {
    navigate('/AddStudent');  
  }
  return (
    <div className="dashboard-container">
      <div className="overview-section">
        <div>
          <h2 className="section-title">Overview</h2>
          <div className="overview-metrics">
            <div className="metric-box active-students">
              <p className="metric-value">1,200</p>
              <p className="metric-label">Active Students</p>
              <p className="metric-change">▼ 75%</p>
            </div>
            <div className="metric-box total-assessments">
              <p className="metric-value">300</p>
              <p className="metric-label">Total Assessments</p>
              <p className="metric-change">▼ 60%</p>
            </div>
            <div className="metric-box average-score">
              <p className="metric-value">85</p>
              <p className="metric-label">Average Score</p>
              <p className="metric-change">▼ 90%</p>
            </div>
          </div>
        </div>
      </div>
      <div className="add-student-header">
          <h2 className="section-title">Add New Students</h2>
          <button className="student-button"onClick={handleAddStudentClick}>Add New Students</button>
      </div>
      <div className="recent-students-section">
        <h2 className="section-title">Top Performers Students</h2>
        <table className="students-table">
          <thead>
            <tr>
              <th>Student Name</th>
              <th>Assessment Score</th>
              <th>Average Score</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>John Doe</td>
              <td>85</td>
              <td>78</td>
              <td className="status">
                <span className="status-badge needs-improvement">needs improvement</span>
                <span className="status-badge good">good</span>
              </td>
              <td><button className="view-button">View</button></td>
            </tr>
            <tr>
              <td>Jane Smith</td>
              <td>92</td>
              <td>88</td>
              <td className="status">
                <span className="status-badge excellent">excellent</span>
                <span className="status-badge good">good</span>
              </td>
              <td><button className="view-button">View</button></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="recent-students-section">
        <h2 className="section-title">Students Need Assistance</h2>
        <table className="students-table">
          <thead>
            <tr>
              <th>Student Name</th>
              <th>Assessment Score</th>
              <th>Average Score</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Swaroop</td>
              <td>85</td>
              <td>78</td>
              <td className="status">
                <span className="status-badge needs-improvement">needs improvement</span>
                <span className="status-badge good">good</span>
              </td>
              <td><button className="view-button">View</button></td>
            </tr>
            <tr>
              <td>Jane Smith</td>
              <td>92</td>
              <td>88</td>
              <td className="status">
                <span className="status-badge excellent">excellent</span>
                <span className="status-badge good">good</span>
              </td>
              <td><button className="view-button">View</button></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="charts-section">
        <div className="chart-box">
          <h3>Student Proficiency in Subjects</h3>
          <p>Comparison of student proficiency across subjects</p>
          <div className="chart-container">
            <Doughnut data={data} />
          </div>
          <div className="chart-controls">
            <button className="filter-button">Filter</button>
            <button className="export-button">Export</button>
          </div>
        </div>

        <div className="chart-box">
          <h3>Topics Requiring Extra Assistance</h3>
          <div className="chart-container">
            <Doughnut data={data} />
          </div>
          <div className="chart-controls">
            <button className="filter-button">Filter</button>
            <button className="export-button">Export</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
