// Dialog.js
import React, { useState, useEffect } from 'react';
import './Dialog.css';

export const Dialog = ({ open, onOpenChange, children }) => {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleClose = () => {
    setIsOpen(false);
    if (onOpenChange) {
      onOpenChange(false);
    }
  };

  if (!isOpen) return null;

  return React.createElement('div', {
    className: 'dialog-overlay',
    onClick: handleClose
  }, 
    React.createElement('div', {
      className: 'dialog-content',
      onClick: e => e.stopPropagation()
    }, children)
  );
};

export const DialogContent = ({ children, className }) => {
  return React.createElement('div', {
    className: `dialog-content-wrapper ${className || ''}`
  }, children);
};

export const DialogHeader = ({ children, className }) => {
  return React.createElement('div', {
    className: `dialog-header ${className || ''}`
  }, children);
};

export const DialogFooter = ({ children, className }) => {
  return React.createElement('div', {
    className: `dialog-footer ${className || ''}`
  }, children);
};

export const DialogTitle = ({ children, className }) => {
  return React.createElement('h2', {
    className: `dialog-title ${className || ''}`
  }, children);
};