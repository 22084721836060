/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import "./ExpertActivityAssistant.css";
import {
  getAllEducationalContent,
  generateActivity,
  updateActivity,
} from "../api";
import Markdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import CryptoJS from "crypto-js";
import CustomButton from "../components/CustomButton";
import LoadingAnimation from "../components/LoadingAnimation";

const generateSessionID = () =>
  `${new Date().getTime()}-${Math.random().toString(36).substr(2, 9)}`;

const ExpertActivityAssistant = () => {
  const encryptData = (data) => {
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
  };
  const decryptData = (encryptedData) => {
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (error) {
      console.error("Decryption failed:", error);
      return null;
    }
  };
  const [formData, setFormData] = useState({
    activityName: "",
    subject: "",
    grade: "",
    board: "",
    selectedChapter: "",
    description: "",
    clarification: "",
    sessionID: "",
  });
  // Using useRef to store the session ID so it doesn't trigger re-renders
  const sessionIdRef = useRef(
    localStorage.getItem("apgenID")
      ? decryptData(localStorage.getItem("apgenID"))
      : generateSessionID()
  );
  const newSessionID = useRef(""); // Ref to hold the new session ID
  // Retrieve sessionID from localStorage or generate a new one if not present
  const [sessionID] = useState(() => localStorage.getItem("sessionID")) || [];

  const [educationalContent, setEducationalContent] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [chatMessages, setChatMessages] = useState(() => {
    const encryptedMessages = localStorage.getItem("chatMessages");
    return encryptedMessages ? decryptData(encryptedMessages) : [];
  });

  const [inputMessage, setInputMessage] = useState("");
  const [showForm, setShowForm] = useState(true);
  const [showChat, setShowChat] = useState(false);
  const [enableButtons, setEnableButtons] = useState(false);
  const [enableChatInput, setEnableChatInput] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const chatEndRef = useRef(null);
  const formRef = useRef(null);

  useEffect(() => {
    window.onbeforeunload = () => {
      localStorage.removeItem("apgenID");
      localStorage.removeItem("chatMessages");
    };

    setIsLoading(true);
    getAllEducationalContent()
      .then((data) => {
        setEducationalContent(JSON.parse(data));
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.style.opacity = "1";
    }
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatMessages]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowForm(false);
    setShowChat(true);

    document
      .querySelector(".lesson-plan-form-container")
      .classList.add("hidden");
    setTimeout(() => {
      document
        .querySelector("._lessson_chat-container")
        .classList.add("visible");
    }, 500);
    console.log("Submitting form...");
    console.log("Session ID used:", sessionID);

    newSessionID.current = generateSessionID(); // Update ref value
    sessionIdRef.current = newSessionID.current; // Optionally update this ref if needed
    localStorage.setItem("apgenID", encryptData(newSessionID.current)); // Correct usage of ref
    setIsLoading(true);
    const planDetails = {
      activityName: formData.activityName,
      grade: formData.grade,
      board: formData.board,
      subject: formData.subject,
      chapter: formData.selectedChapter,
      sender: "Activity_planner",
      sessionID: sessionID,
      description: formData.description,
      chatID: `apgenID_${newSessionID.current}`,
    };

    generateActivity(planDetails)
      .then((response) => {
        console.log("Activity plan generated:", response);
        setIsLoading(false);
        const responseMessage =
          response.message ||
          `Activity generated: ${response.details || "Details not available"}`;
        const newMessages = [
          ...chatMessages,
          { text: responseMessage, isUser: false },
        ];
        setChatMessages(newMessages);
        localStorage.setItem("chatMessages", encryptData(newMessages));
        setEnableButtons(true);
      })
      .catch((error) => {
        console.error("Failed to generate Activity:", error);
        const newMessages = [
          ...chatMessages,
          {
            text: "Failed to generate Activity due to an error.",
            isUser: false,
          },
        ];
        setChatMessages(newMessages);
        localStorage.setItem("chatMessages", JSON.stringify(newMessages));
        setEnableButtons(false);
      });
  };

  const handleChatSubmit = () => {
    if (inputMessage.trim()) {
      const newMessages = [
        ...chatMessages,
        { text: inputMessage, isUser: true },
      ];
      setChatMessages(newMessages);
      localStorage.setItem("chatMessages", encryptData(newMessages));

      const planDetails = {
        activityName: formData.activityName,
        grade: formData.grade,
        board: formData.board,
        subject: formData.subject,
        chapter: formData.selectedChapter,
        sender: "Activity_planner",
        sessionID: sessionID,
        description: inputMessage,
        chatID: `apgenID_${newSessionID.current}`,
      };

      // Call the API to send data including user's message for clarification
      generateActivity(planDetails)
        .then((response) => {
          console.log("Clarification and plan details sent:", response);
          const responseMessage =
            response.message || "Clarification request submitted successfully.";
          const updatedMessages = [
            ...newMessages,
            { text: responseMessage, isUser: false },
          ];
          setChatMessages(updatedMessages);
          localStorage.setItem("chatMessages", encryptData(updatedMessages));
          setEnableButtons(true);
        })
        .catch((error) => {
          console.error("Failed to send clarification:", error);
          const errorMessages = [
            ...newMessages,
            {
              text: "Failed to send clarification due to an error.",
              isUser: false,
            },
          ];
          setChatMessages(errorMessages);
          localStorage.setItem("chatMessages", JSON.stringify(errorMessages));
          setEnableButtons(false);
        });

      // Clear the input field
      setInputMessage("");
    }
  };

  const handleNeedClarification = () => {
    setEnableChatInput(true);
    setEnableButtons(false);
  };

  const handleConfirm = () => {
    setEnableChatInput(false);
    setEnableButtons(true);

    // Assuming the last received system message contains the details needed for the update
    const lastSystemMessage = chatMessages.filter((msg) => !msg.isUser).pop();
    const encryptedUserData = localStorage.getItem("userData");
    const userDetails = encryptedUserData ? decryptData(encryptedUserData) : {};

    const updateDetails = {
      activityName: formData.activityName,
      sessionID: sessionID,
      chatID: `apgenID_${newSessionID.current}`,
      username: userDetails.username,
      grade: formData.grade,
      subject: formData.subject,
      board: formData.board,
      chapter: formData.selectedChapter,
      sender: "acitivity_gen",
      activity: lastSystemMessage
        ? lastSystemMessage.text
        : "No last response available",
    };

    updateActivity(updateDetails)
      .then((response) => {
        console.log("Update sent:", response);
        // Handle response here
      })
      .catch((error) => {
        console.error("Failed to update Activity:", error);
        // Handle error here
      });
  };

  const handleFocus = (e) => {
    e.target.parentNode.classList.add("input-focus");
  };

  const handleBlur = (e) => {
    e.target.parentNode.classList.remove("input-focus");
  };

  const grades = Object.keys(educationalContent).map((key) => ({
    classid: key,
    name: `Grade ${key}`,
  }));
  const boards = formData.grade
    ? Object.keys(educationalContent[formData.grade]).map((key) => ({
        id: key,
        name: key,
      }))
    : [];
  const subjects =
    formData.grade && formData.board
      ? Object.keys(educationalContent[formData.grade][formData.board]).map(
          (key) => ({ id: key, name: key })
        )
      : [];
  const chapters =
    formData.grade && formData.board && formData.subject
      ? educationalContent[formData.grade][formData.board][formData.subject]
      : [];

  return (
    <div className={`advanced-lesson-plan-generator`}>
      {isLoading && <LoadingAnimation />}

      <div className="main-content">
        {showForm && (
          <div className="lesson-plan-form-container">
            <h2 className="form-title">Generate Your Activity</h2>
            <form onSubmit={handleSubmit} className="lesson-plan-form">
              <div className="row full-width">
                <input
                  type="text"
                  className="lessonPlan_input"
                  name="activityName"
                  placeholder="Give A Name To your Activity"
                  value={formData.activityName}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="row">
                <div className="column">
                  <select
                    name="grade"
                    className="lessonPlan_input_select"
                    value={formData.grade}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Grade</option>
                    {grades.map((grade) => (
                      <option key={grade.classid} value={grade.classid}>
                        {grade.name}
                      </option>
                    ))}
                  </select>
                  <select
                    name="subject"
                    value={formData.subject}
                    onChange={handleInputChange}
                    required
                    className="lessonPlan_input_select"
                  >
                    <option value="">Select Subject</option>
                    {subjects.map((subject) => (
                      <option key={subject.id} value={subject.id}>
                        {subject.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="column">
                  <select
                    name="board"
                    value={formData.board}
                    onChange={handleInputChange}
                    required
                    className="lessonPlan_input_select"
                  >
                    <option value="">Select Board</option>
                    {boards.map((board) => (
                      <option key={board.id} value={board.id}>
                        {board.name}
                      </option>
                    ))}
                  </select>
                  <select
                    name="selectedChapter"
                    value={formData.selectedChapter}
                    onChange={handleInputChange}
                    required
                    className="lessonPlan_input_select"
                  >
                    <option value="">Select Chapter</option>
                    {chapters.map((chapter, index) => (
                      <option
                        key={`${chapter.chaptername}-${index}`}
                        value={chapter.chaptername}
                      >{`${chapter.chaptername} (${chapter.chapternumber})`}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row">
                <textarea
                  name="description"
                  placeholder="Specific Topic"
                  value={formData.description}
                  onChange={handleInputChange}
                  className="lessonPlan_input_textarea"
                ></textarea>
              </div>
              {errorMsg && <div className="error-message">{errorMsg}</div>}
              <CustomButton
                text={isLoading ? "Generating..." : "Generate Activity"}
                type="submit"
                isDisabled={isLoading}
                className="custom_button max btn_dark_bg"
              ></CustomButton>
            </form>
          </div>
        )}
        {showChat && (
          <div className="_lessson_chat-container">
            <div className="chat-messages">
              {chatMessages.map((msg, index) => (
                <div
                  key={index}
                  className={`message ${msg.isUser ? "user" : "system"}`}
                >
                  <Markdown
                    children={msg.text}
                    components={{
                      code({ node, inline, className, children, ...props }) {
                        const match = /language-(\w+)/.exec(className || "");
                        return !inline && match ? (
                          <SyntaxHighlighter
                            style={dark}
                            language={match[1]}
                            PreTag="div"
                            {...props}
                          >
                            {String(children).replace(/\n$/, "")}
                          </SyntaxHighlighter>
                        ) : (
                          <code className={className} {...props}>
                            {children}
                          </code>
                        );
                      },
                    }}
                  />
                </div>
              ))}
              <div ref={chatEndRef} />
            </div>
            <div className="button-container">
              <button disabled={!enableButtons} onClick={handleConfirm}>
                Confirm
              </button>
              <button
                disabled={!enableButtons}
                onClick={handleNeedClarification}
              >
                Need Clarification
              </button>
            </div>
            <div className="chat-input">
              <input
                type="text"
                className={
                  enableChatInput
                    ? "input-glow lessonPlan_input"
                    : "lessonPlan_input"
                }
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                placeholder="Type a message..."
                disabled={!enableChatInput}
              />
              <button
                onClick={handleChatSubmit}
                disabled={!enableChatInput || !inputMessage.trim()}
              >
                Send
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExpertActivityAssistant;
