import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './QuestionAnswer.css';
import { fetchSubjects, updateUserType, fetchUpdatedUserDetails } from '../api';
import LoadingAnimation from '../components/LoadingAnimation';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const QuestionAnswer = () => {
  const [step, setStep] = useState(0);
  const [userType, setUserType] = useState('');
  const [classes, setClasses] = useState([]);
  const [boards, setBoards] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [availableSubjects, setAvailableSubjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Function to fetch available subjects
  const fetchAvailableSubjects = useCallback(async () => {
    if (classes.length > 0 && boards.length > 0) {
      try {
        setLoading(true);
        const subjects = await fetchSubjects(classes, boards);
        setAvailableSubjects(subjects || []); // Ensure it's always an array
      } catch (error) {
        console.error('Error fetching subjects:', error);
        setAvailableSubjects([]); // Set as empty array on error
      } finally {
        setLoading(false);
      }
    }
  }, [classes, boards]); // Dependencies for useCallback
  
  useEffect(() => {
    fetchAvailableSubjects();
  }, [fetchAvailableSubjects]); 

  const handleMultiSelectChange = (e, setState) => {
    const { options } = e.target;
    const values = Array.from(options).filter(option => option.selected).map(option => option.value);
    setState(values);
    console.log('Selected values:', values);
  };

  const handleSubmit = async () => {
    const userData = JSON.parse(localStorage.getItem('userData') || '{}');
    const username = userData.username;
    try {
      setLoading(true);  // Start loading animation
      await updateUserType({ username, userType, classes, boards, subjects });
      localStorage.removeItem('userData');
      localStorage.removeItem('userType');
      localStorage.removeItem('classes');
      localStorage.removeItem('boards');
      localStorage.removeItem('subjects');
      const updatedUserData = await fetchUpdatedUserDetails(username);
      localStorage.setItem('userData', JSON.stringify(updatedUserData));
      setUserType(updatedUserData.usertype); // Update userType state
      navigate('/saas/home');
      window.location.reload();  // Force a page refresh
    } catch (error) {
      console.error('Error submitting details:', error);
      alert(error.message);
    } finally {
      setLoading(false);  // Stop loading animation regardless of the outcome
    }
  };

  const handleUserTypeSelection = () => {
    if (!userType) {
      alert('Please select user type.');
    } else {
      localStorage.setItem('userType', userType);
      // setStep(1); // Move to next step only if userType is selected
      setStep(2);
      console.log('UserType selected:', userType);
    }
  };

  const handleClassSelection = () => {
    if (userType === 'teacher' && classes.length === 0) {
      alert('Please select at least one class.');
    } else {
      localStorage.setItem('classes', JSON.stringify(classes));
      // setStep(2);
      setStep(3); // Move to next step only if classes are selected or user is a student
      console.log('Classes selected:', classes);
    }
  };

  const handleBoardSelection = () => {
    if (boards.length === 0) {
      alert('Please select at least one board.');
    } else {
      localStorage.setItem('boards', JSON.stringify(boards));
      console.log('Boards selected:', boards);
      fetchAvailableSubjects();  // Fetch subjects based on selected classes and boards
      setStep(4);  // Move to next step to display subjects
    }
};
  const handleSubjectSelection = () => {
    if (subjects.length === 0) {
      alert('Please select at least one subject.');
    } else {
      localStorage.setItem('subjects', JSON.stringify(subjects));
      handleSubmit(); // Submit and transition to the next page
      console.log('Subjects selected:', subjects);
    }
  };

  const handleCancel = async () => {
    const username = JSON.parse(localStorage.getItem('userData')).username;
    try {
      await updateUserType({ username, userType: 'saas' });
      navigate('/saas/home');
    } catch (error) {
      console.error('Error updating user type:', error);
    }
  };

  return (
    <>
      {loading && <LoadingAnimation />}
      <div className={`qa-container ${loading ? 'loading' : ''}`}>
        <TransitionGroup className="qa-box">
          <CSSTransition key={step} timeout={500} classNames="fade">
            <div>
              {step === 0 && (
                <>
                  <h2>To Provide the Best in Class Service, We need to Proceed with a Small Q A session are you ready?</h2>
                  <div className="qa-buttons">
                    <button className="qa-button" onClick={() => setStep(1)}>Yes</button>
                    <button className="qa-button" onClick={handleCancel}>No</button>
                  </div>
                </>
              )}
              {step === 1 && (
                <>
                  <h2>Select User Type</h2>
                  <div className="qa-form-row">
                    <div className="qa-input-group">
                      <select id="userType" value={userType} onChange={(e) => setUserType(e.target.value)} required>
                        <option value="">Select User Type</option>
                        <option value="teacher">Teacher</option>
                        <option value="student">Student</option>
                      </select>
                    </div>
                  </div>
                  <div className="qa-buttons">
                    <button className="qa-button" onClick={handleUserTypeSelection}>Next</button>
                    <button className="qa-button" onClick={handleCancel}>Cancel</button>
                  </div>
                </>
              )}
              {step === 2 && userType === 'teacher' && (
                <>
                  <h2>What classes do you teach?</h2>
                  <div className="qa-form-row">
                    <div className="qa-input-group">
                      <select multiple onChange={(e) => handleMultiSelectChange(e, setClasses)}>
                        {Array.from({ length: 12 }, (_, i) => `Class ${i + 1}`).map((cls) => (
                          <option key={cls} value={cls}>{cls}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="qa-buttons">
                    <button className="qa-button" onClick={handleClassSelection}>Next</button>
                  </div>
                </>
              )}
              {step === 3 && userType === 'teacher' && (
                <>
                  <h2>What boards are you interested in?</h2>
                  <div className="qa-form-row">
                    <div className="qa-input-group">
                      <select multiple onChange={(e) => handleMultiSelectChange(e, setBoards)}>
                        {(() => {
                          const selectedClasses = classes.map(cls => parseInt(cls.replace('Class ', ''), 10));
                          const options = [];
                          if (selectedClasses.some(cls => cls <= 10)) {
                            options.push('APSTATE', 'NCERT');
                          }
                          if (selectedClasses.some(cls => cls > 10)) {
                            options.push('State', 'Central');
                          }
                          return options;
                        })().map(board => (
                          <option key={board} value={board}>{board}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="qa-buttons">
                    <button className="qa-button" onClick={handleBoardSelection}>Next</button>
                  </div>
                </>
              )}
              {step === 4 && userType === 'teacher' && (
                <>
                  <h2>Select the desired subjects you are interested in</h2>
                  <div className="qa-form-row">
                    <div className="qa-input-group">
                      <select multiple onChange={(e) => handleMultiSelectChange(e, setSubjects)}>
                        {availableSubjects.map(subject => (
                          <option key={subject} value={subject}>{subject}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="qa-buttons">
                    <button className="qa-button" onClick={handleSubjectSelection}>Submit</button>
                  </div>
                </>
              )}
            </div>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </>
  );
};

export default QuestionAnswer;
