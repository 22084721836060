import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateAssessment, getGradesInstitutes, studentAssessment } from "../api";
import "./Assessment.css";
import CryptoJS from "crypto-js";

// Custom Alert Components
const Alert = ({ children, onClose }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            {children}
            {onClose && (
                <button
                    onClick={onClose}
                    className="mt-4 bg-gray-200 text-gray-800 px-4 py-2 rounded hover:bg-gray-300"
                >
                    Close
                </button>
            )}
        </div>
    </div>
);

const AlertTitle = ({ children }) => (
    <h2 className="text-lg font-semibold mb-2">{children}</h2>
);

const AlertDescription = ({ children }) => (
    <p className="text-gray-600 mb-4">{children}</p>
);

const AlertDialog = ({ children, open, onOpenChange }) => (
    open ? (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
                {children}
            </div>
        </div>
    ) : null
);

const AlertDialogAction = ({ children, onClick }) => (
    <button
        onClick={onClick}
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mr-2"
    >
        {children}
    </button>
);

const decryptData = (encryptedData) => {
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (error) {
        console.error("Decryption failed:", error);
        return null;
    }
};

const AssessmentPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [questions, setQuestions] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [answers, setAnswers] = useState({});
    const [showAnswers, setShowAnswers] = useState(false);
    const [totalScore, setTotalScore] = useState(0);
    const [assessmentData, setAssessmentData] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showPublishModal, setShowPublishModal] = useState(false);
    const [gradesInstitutes, setGradesInstitutes] = useState({ grades: [], institutes: [] });
    const [selectedGrades, setSelectedGrades] = useState([]);
    const [selectedInstitutes, setSelectedInstitutes] = useState([]);
    const [showHomeConfirmation, setShowHomeConfirmation] = useState(false);

    useEffect(() => {
        if (location.state && location.state.questions) {
            try {
                const parsedQuestions = parseQuestions(location.state.questions);
                setQuestions(parsedQuestions);
                setAssessmentData(location.state.questions);
            } catch (error) {
                console.error("Error submitting assessment:", error);
                alert("Error submitting assessment. Please try again.");
            }
        } else {
            console.warn("No data passed via state or incorrect data structure:", location.state);
        }
    }, [location.state]);

    const parseQuestions = (apiResponse) => {
        if (!apiResponse || !apiResponse.text || !apiResponse.text.sections) {
            console.error('API response is missing expected sections', apiResponse);
            return [];
        }

        let questionId = 0;
        return apiResponse.text.sections.flatMap(section =>
            section.questions.map(question => {
                questionId++;
                return {
                    id: questionId,
                    type: section.type,
                    ...question
                };
            })
        );
    };

    const handleHomeClick = () => {
        setShowHomeConfirmation(true);
        setShowSuccessAlert(false);
    };

    const handleHomeConfirm = () => {
        navigate('/TeacherHome'); // Adjust this path if your home route is different
    };

    const handleHomeCancel = () => {
        setShowHomeConfirmation(false);
        setShowSuccessAlert(true);
    };

    const handleAnswer = (id, answer) => {
        setAnswers(prev => ({ ...prev, [id]: answer }));
    };

    const handleSubmit = async () => {
        const retrieveStoredFormData = () => {
            const encryptedData = localStorage.getItem("encryptedFormData");
            if (encryptedData) {
                const decryptedData = decryptData(encryptedData);
                return typeof decryptedData === 'object' ? decryptedData : JSON.parse(decryptedData);
            }
            return null;
        };

        const encryptedUserData = localStorage.getItem("userData");
        const userDetails = encryptedUserData ? decryptData(encryptedUserData) : {};

        try {
            const decryptedData = retrieveStoredFormData();

            if (!decryptedData) {
                throw new Error("No stored assessment data found");
            }

            const response = await updateAssessment({ ...assessmentData, ...decryptedData, username: userDetails.username });

            if (response.status === "Success") {
                localStorage.setItem("assessment_id", response.assessment_id);
                calculateScore();
                setShowSuccessAlert(true);
            } else if (response.status === "Error") {
                alert("Error updating assessment: " + response.message);
            } else {
                throw new Error("Unexpected response from server");
            }
        } catch (error) {
            alert("Error submitting assessment. Please try again.");
        }
    };

    const handleOfflinePaper = () => {
        navigate('/offline-paper', { state: { assessmentData } });
        setShowSuccessAlert(false);
    };

    const handlePublish = async () => {
        const encryptedUserData = localStorage.getItem("userData");
        const userDetails = encryptedUserData ? decryptData(encryptedUserData) : {};
        try {
            const response = await getGradesInstitutes(userDetails.username);
            const data = await response.json();

            if (data && data.grades_institutes) {
                setGradesInstitutes({
                    grades: data.grades_institutes.map(item => item.grade),
                    institutes: data.grades_institutes.map(item => item.institute)
                });
            } else {
                console.error('Unexpected data structure:', data);
                setGradesInstitutes({ grades: [], institutes: [] });
            }
            setShowPublishModal(true);
        } catch (error) {
            console.error("Error fetching grades and institutes:", error);
            alert("Error fetching grades and institutes. Please try again.");
        }
    };

    const handleGradeChange = (grade) => {
        setSelectedGrades(prev =>
            prev.includes(grade) ? prev.filter(g => g !== grade) : [...prev, grade]
        );
    };

    const handleInstituteChange = (institute) => {
        setSelectedInstitutes(prev =>
            prev.includes(institute) ? prev.filter(i => i !== institute) : [...prev, institute]
        );
    };

    const handlePublishConfirm = async () => {
        const encryptedUserData = localStorage.getItem("userData");
        const userDetails = encryptedUserData ? decryptData(encryptedUserData) : {};
        const assessmentId = localStorage.getItem("assessment_id");

        if (!assessmentId) {
            alert("Error: Assessment ID not found. Please try submitting the assessment again.");
            return;
        }

        try {
            const response = await studentAssessment({
                assessment_id: assessmentId,
                username: userDetails.username,
                grades: selectedGrades,
                institutes: selectedInstitutes,
                action: "publish"
            });

            if (response.status === "Success") {
                setShowPublishModal(false);
                setShowSuccessAlert(true);
            } else {
                throw new Error(response.message || "Failed to publish assessment");
            }
        } catch (error) {
            alert("Error publishing assessment. Please try again.");
        }
    };

    const handleCreateTemporaryLink = () => {
        console.log("Create Temporary Link button clicked");
        setShowSuccessAlert(false);
    };

    const calculateScore = () => {
        let score = 0;
        questions.forEach((question) => {
            const userAnswer = answers[question.id];
            const correctAnswer = question.answer;
            if (question.type === 'matchTheFollowing') {
                const userMatches = Object.entries(userAnswer || {});
                const correctMatches = Array.isArray(correctAnswer)
                    ? correctAnswer.map(match => Object.entries(match)[0])
                    : Object.entries(correctAnswer || {});
                const correctCount = userMatches.filter(([key, value]) =>
                    correctMatches.some(([cKey, cValue]) => key === cKey && value === cValue)
                ).length;
                score += (correctCount / correctMatches.length) * question.marks;
            } else if (userAnswer === correctAnswer) {
                score += question.marks;
            }
        });
        setTotalScore(score);
    };

    const handleNavigation = (questionIndex) => {
        setCurrentQuestion(questionIndex);
    };

    const toggleShowAnswers = () => {
        setShowAnswers(!showAnswers);
    };

    const handleNext = () => {
        if (currentQuestion < questions.length - 1) {
            setCurrentQuestion(currentQuestion + 1);
        }
    };

    const handleModify = () => {
        const currentQuestionData = questions[currentQuestion];
        if (!currentQuestionData) {
            console.error("Current question data is undefined");
            return;
        }

        const updatedAnswer = answers[currentQuestionData.id];
        if (updatedAnswer === undefined) {
            console.error("No answer to modify");
            return;
        }

        const updatedQuestions = [...questions];
        updatedQuestions[currentQuestion] = {
            ...currentQuestionData,
            answer: updatedAnswer
        };
        setQuestions(updatedQuestions);

        if (assessmentData && assessmentData.text && assessmentData.text.sections) {
            const updatedAssessmentData = { ...assessmentData };
            const sectionIndex = updatedAssessmentData.text.sections.findIndex(section =>
                section.questions.some(q => q.question === currentQuestionData.question)
            );

            if (sectionIndex !== -1) {
                const questionIndex = updatedAssessmentData.text.sections[sectionIndex].questions.findIndex(
                    q => q.question === currentQuestionData.question
                );

                if (questionIndex !== -1) {
                    updatedAssessmentData.text.sections[sectionIndex].questions[questionIndex].answer = updatedAnswer;
                    setAssessmentData(updatedAssessmentData);
                }
            }
        }
    };

    const renderQuestion = (question) => {
        switch (question.type) {
            case 'multipleChoice':
                return (
                    <div className="question">
                        <p className="topic">Topic: {question.topic}</p>
                        <p>{question.question}</p>
                        {question.options.map((option, index) => (
                            <div key={index} className="option">
                                <input
                                    type="radio"
                                    id={`option-${question.id}-${index}`}
                                    name={`question-${question.id}`}
                                    value={option}
                                    checked={answers[question.id] === option}
                                    onChange={(e) => handleAnswer(question.id, e.target.value)}
                                />
                                <label htmlFor={`option-${question.id}-${index}`}>{option}</label>
                            </div>
                        ))}
                        {showAnswers && (
                            <div className="answer-feedback">
                                <p>Correct Answer: {question.answer}</p>
                                <p>Marks: {question.marks}</p>
                            </div>
                        )}
                    </div>
                );
            case 'matchTheFollowing':
                return (
                    <div className="question matching-question">
                        <p className="topic">Topic: {question.topic}</p>
                        <p>{question.question}</p>
                        <div className="matching-container">
                            {question.items.map((item, index) => (
                                <div key={index} className="term-item">
                                    <span className="term-label">{Object.keys(item)[0]}:</span>
                                    <span className="term-content">{Object.values(item)[0]}</span>
                                    <select
                                        value={(answers[question.id] && answers[question.id][Object.keys(item)[0]]) || ''}
                                        onChange={(e) => handleAnswer(question.id, { ...answers[question.id], [Object.keys(item)[0]]: e.target.value })}
                                    >
                                        <option value="">Select Match</option>
                                        {question.matches.map((match, matchIndex) => (
                                            <option key={matchIndex} value={Object.keys(match)[0]}>
                                                {Object.keys(match)[0]}: {Object.values(match)[0]}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            ))}
                        </div>
                        {showAnswers && (
                            <div className="answer-feedback">
                                <p>Correct Answers:</p>
                                {Array.isArray(question.answer) ? (
                                    question.answer.map((ans, index) => (
                                        <p key={index}>{Object.keys(ans)[0]}: {Object.values(ans)[0]}</p>
                                    ))
                                ) : (
                                    Object.entries(question.answer || {}).map(([key, value], index) => (
                                        <p key={index}>{key}: {value}</p>
                                    ))
                                )}
                                <p>Marks: {question.marks}</p>
                            </div>
                        )}
                    </div>
                );
            case 'fillInTheBlanks':
            case 'textInput':
                return (
                    <div className="question">
                        <p className="topic">Topic: {question.topic}</p>
                        <p>{question.question || question.sentence}</p>
                        <textarea
                        className='assesment-textarea'
                            value={answers[question.id] || ''}
                            onChange={(e) => handleAnswer(question.id, e.target.value)}
                            rows="4"
                        />
                        {showAnswers && (
                            <div className="answer-feedback">
                                <p>Correct Answer: {question.answer}</p>
                                <p>Marks: {question.marks}</p>
                            </div>
                        )}
                    </div>
                );
            default:
                return <div>Unknown question type: {question.type}</div>;
        }
    };

    return (
        <div className="container">
            <h1>Assessment</h1>
            <div className="assessment-layout">
                <div className="question-area">
                    {questions.length > 0 ? (
                        <div className="question-container">
                            <h2>Question {currentQuestion + 1}</h2>
                            {renderQuestion(questions[currentQuestion])}
                            <div className="button-group">
                                {currentQuestion < questions.length - 1 && (
                                    <button className="submit-button" onClick={handleNext}>
                                        Next
                                    </button>
                                )}
                                <button className="submit-button" onClick={handleModify}>
                                    Modify
                                </button>
                                {currentQuestion === questions.length - 1 && (
                                    <button className="submit-button" onClick={handleSubmit}>
                                        Submit
                                    </button>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div>No questions to display</div>
                    )}
                </div>
                <div className="navigation-area">
                    <h2>Questions</h2>
                    <div className="question-nav">
                        {questions.map((q, index) => (
                            <button
                                key={q.id}
                                className={`nav-button ${currentQuestion === index ? 'active' : ''} ${answers[q.id] ? 'answered' : ''}`}
                                onClick={() => handleNavigation(index)}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                    <button className="show-answers-button" onClick={toggleShowAnswers}>
                        {showAnswers ? 'Hide Answers' : 'Show Answers'}
                    </button>
                    <div className="total-score">
                        <h3>Total Score: {totalScore.toFixed(2)}</h3>
                    </div>
                </div>
            </div>

            {showSuccessAlert && (
                <AlertDialog open={showSuccessAlert} onOpenChange={setShowSuccessAlert}>
                    <Alert>
                        <AlertTitle>Completed</AlertTitle>
                        <AlertDescription>
                            Your Assessment has been submitted successfully. Please select one of the options below to assign to the students:
                        </AlertDescription>
                        <div className="mt-4 flex justify-center space-x-2">
                            <AlertDialogAction onClick={handleOfflinePaper}>
                                Offline Paper
                            </AlertDialogAction>
                            <AlertDialogAction onClick={handlePublish}>
                                Publish
                            </AlertDialogAction>
                            <AlertDialogAction onClick={handleCreateTemporaryLink}>
                                Create Temporary Link
                            </AlertDialogAction>
                            <AlertDialogAction onClick={handleHomeClick}>
                                Home
                            </AlertDialogAction>
                        </div>
                    </Alert>
                </AlertDialog>
            )}
            {showHomeConfirmation && (
                <AlertDialog open={showHomeConfirmation} onOpenChange={setShowHomeConfirmation}>
                    <Alert>
                        <AlertTitle>Warning</AlertTitle>
                        <AlertDescription>
                            The submitted assessment will be stored but not be published to any students. Do you want to proceed to the home page?
                        </AlertDescription>
                        <div className="mt-4 flex justify-center space-x-2">
                            <AlertDialogAction onClick={handleHomeConfirm}>
                                Yes
                            </AlertDialogAction>
                            <AlertDialogAction onClick={handleHomeCancel}>
                                No
                            </AlertDialogAction>
                        </div>
                    </Alert>
                </AlertDialog>
            )}

            {showPublishModal && (
                <AlertDialog open={showPublishModal} onOpenChange={setShowPublishModal}>
                    <Alert>
                        <AlertTitle>Publish Assessment</AlertTitle>
                        <AlertDescription>
                            Select grades and institutes to publish your assessment:
                        </AlertDescription>
                        <div className="mt-4">
                            <h3>Grades:</h3>
                            {gradesInstitutes.grades.map(grade => (
                                <div key={grade} className="flex items-center mb-2">
                                    <input
                                        type="checkbox"
                                        id={`grade-${grade}`}
                                        checked={selectedGrades.includes(grade)}
                                        onChange={() => handleGradeChange(grade)}
                                        className="mr-2"
                                    />
                                    <label htmlFor={`grade-${grade}`}>{grade}</label>
                                </div>
                            ))}
                            <h3 className="mt-4">Institutes:</h3>
                            {gradesInstitutes.institutes.map(institute => (
                                <div key={institute} className="flex items-center mb-2">
                                    <input
                                        type="checkbox"
                                        id={`institute-${institute}`}
                                        checked={selectedInstitutes.includes(institute)}
                                        onChange={() => handleInstituteChange(institute)}
                                        className="mr-2"
                                    />
                                    <label htmlFor={`institute-${institute}`}>{institute}</label>
                                </div>
                            ))}
                        </div>
                        <div className="mt-4 flex justify-center space-x-2">
                            <AlertDialogAction onClick={handlePublishConfirm}>
                                Publish
                            </AlertDialogAction>
                            <AlertDialogAction onClick={() => setShowPublishModal(false)}>
                                Cancel
                            </AlertDialogAction>
                        </div>
                    </Alert>
                </AlertDialog>
            )}
        </div>
    );
};

export default AssessmentPage;
