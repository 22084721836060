import React, { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Schedules.css';

const localizer = momentLocalizer(moment);

const Schedules = () => {
    const [events, setEvents] = useState([]);
    const navigate = useNavigate();

    const handleSelectSlot = ({ start, end }) => {
        const title = window.prompt('New Event name');
        if (title) {
            setEvents(prevEvents => [
                ...prevEvents,
                {
                    start,
                    end,
                    title,
                },
            ]);
        }
    };

    const navigateToChat = () => {
        navigate('/PeerMessage'); // Navigate to ChatPage
    };

    return (
        <div className="schedule-container">
            <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500 }}
                selectable
                onSelectSlot={handleSelectSlot}
            />
            <button onClick={navigateToChat}>Open Chat</button>
        </div>
    );
};

export default Schedules;
