import React, { useState } from "react";
import { generateCourse } from "../../api";
import CryptoJS from "crypto-js";
import Markdown from "react-markdown";
import "./GenerateCourse.css";
import CustomButton from "../../components/CustomButton";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const decryptData = (encryptedData) => {
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error("Decryption failed:", error);
    return null;
  }
};

const CoursePage = () => {
  const [courseInput, setCourseInput] = useState("");
  const [courseLevel, setCourseLevel] = useState("Beginner");
  const [courseData, setCourseData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);

  const handleInputChange = (e) => {
    setCourseInput(e.target.value);
  };

  const handleLevelChange = (e) => {
    setCourseLevel(e.target.value);
  };

  const generateCourseData = async () => {
    try {
      const encryptedUserData = localStorage.getItem("userData");
      const userDetails = encryptedUserData ? decryptData(encryptedUserData) : {};
      const response = await generateCourse(courseInput, courseLevel, userDetails.username);
      setCourseData(response);
      setCurrentPage(0);
    } catch (error) {
      console.error("Error generating course:", error);
    }
  };

  const handleNext = () => {
    if (currentPage < courseData.message.subtopics.length - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="gc_course-page">
      <div className="gc_input-container">
        <input
          type="text"
          value={courseInput}
          onChange={handleInputChange}
          placeholder="Enter a course topic"
          className="gc_course-input"
        />
        <select
          value={courseLevel}
          onChange={handleLevelChange}
          className="gc_course-select"
        >
          <option value="Beginner">Beginner</option>
          <option value="Intermediate">Intermediate</option>
          <option value="Advanced">Advanced</option>
        </select>
        <CustomButton
          text="Generate Course"
          onClickfn={generateCourseData}
          className="custom_button"
        />
      </div>

      {courseData && (
        <div className="gc_course-content">
          <div className="gc_course_title">
            <h2>{courseData.message.topic}</h2>
          </div>
          <div className="gc_document-container">
            <Markdown children={courseData.message.subtopics[currentPage].content} />
            <div className="gc_assessment-container">
              {/* Render assessment here */}
            </div>
          </div>
          <div className="gc_navigation-buttons">
            {currentPage > 0 && (
              <button onClick={handlePrev} className="gc_nav-button">Previous</button>
            )}
            {currentPage < courseData.message.subtopics.length - 1 && (
              <button onClick={handleNext} className="gc_nav-button">Next</button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CoursePage;
