import React from "react";
import "./Button.css";

const Button = ({ as, children, filled, secondary, ...rest }) => {
  const Component = as || "button";
  return (
    <Component
      className={`dir-control ${secondary ? "dir-control--secondary" : ""} ${
        filled ? "dir-control--filled" : ""
      }`}
      {...rest}
    >
      {children}
      <span />
      <span />
      <span />
      <span />
      <b aria-hidden="true">{children}</b>
      <b aria-hidden="true">{children}</b>
      <b aria-hidden="true">{children}</b>
      <b aria-hidden="true">{children}</b>
    </Component>
  );
};

export default Button;
