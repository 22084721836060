// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { User, CreditCard, Building } from 'lucide-react';
// import { changePassword, verifyEmail, verifyPhoneNumber, fetchUpdatedUserDetails } from '../api';
import { fetchUpdatedUserDetails } from '../api';
import CryptoJS from 'crypto-js';
import './MyAccount.css';

const AccountInfo = () => {
  const [activeSection, setActiveSection] = useState('personal');
  const [userData, setUserData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    emailVerified: false,
    phoneVerified: false,
    grade: '',
    institute: '',
    passions: '',
    subscriptionType: '',
    subscriptionValue: '',
    renewalDate: '',
    district: '',
    state: '',
    usertype: ''
  });
  // const [currentPassword, setCurrentPassword] = useState('');
  // const [newPassword, setNewPassword] = useState('');
  // const [confirmPassword, setConfirmPassword] = useState('');
  // const [passwordChanged, setPasswordChanged] = useState(false);
  // const [passwordError, setPasswordError] = useState('');
  // const [emailVerified, setEmailVerified] = useState(false);
  // const [phoneVerified, setPhoneVerified] = useState(false);

  
  // Decryption function
  const decryptData = (encryptedData) => {
    const secretKey = process.env.REACT_APP_SECRET_KEY; // Make sure the secret key is correct
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (error) {
      console.error("Decryption failed:", error);
      return {}; // Return an empty object on failure
    }
  };
  useEffect(() => {
    const encryptedUserData = localStorage.getItem('userData');
    if (encryptedUserData) {
      const decryptedUserData = decryptData(encryptedUserData);
      console.log('Decrypted User Data:', decryptedUserData);
      if (decryptedUserData) {
        setUserData({
          firstname: decryptedUserData.userData.firstname || '',
          lastname: decryptedUserData.userData.lastname || '',
          email: decryptedUserData.userData.email || '',
          phone: decryptedUserData.userData.phone || '',
          grade: decryptedUserData.classes || '',
          institute: '', // Update this field if you have the corresponding data
          passions: '', // Update this field if you have the corresponding data
          subscriptionType: decryptedUserData.subscription_status || '',
          subscriptionValue: '', // Update this field if you have the corresponding data
          renewalDate: decryptedUserData.subscription_end_date || '',
          district: '', // Update this field if you have the corresponding data
          state: decryptedUserData.boards || '',
          usertype: decryptedUserData.usertype
        });
      }
      console.log('Fetched User Data:', userData)
    } else {
      console.log('No user data found in local storage, fetching from backend');
      fetchUserDataFromAPI();
    }
  }, []);


  const fetchUserDataFromAPI = async () => {
    try {
      const data = await fetchUpdatedUserDetails(); // Assuming this function handles authorization
      console.log('Fetched User Data:', data);
      if (data) {
          setUserData({
              firstname: data.firstname,
              lastname: data.lastname,
              email: data.email,
              phone: data.phone,
              emailVerified: false, // Update these based on actual data if available
              phoneVerified: false
          });
      }
      
      localStorage.setItem('userData', JSON.stringify(data));
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
};

  const handleChangePassword = () => {
    alert('Change password functionality would go here');
  };

  const handleDeleteAccount = () => {
    if (window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
      alert('Account deletion functionality would go here');
    }
  };

  const handleUpgradeAccount = () => {
    alert('Upgrade account functionality would go here');
  };

  const PersonalDetails = () => (
    <div className="profile-section">
      <img 
        src="/api/placeholder/100/100" 
        alt="Profile" 
        className="profile-image"
      />
      <div className="form-fields">
        <div className="form-group">
          <label>First Name</label>
          <input type="text" value={userData.firstname} readOnly />
        </div>
        <div className="form-group">
          <label>Last Name</label>
          <input type="text" value={userData.lastname} readOnly />
        </div>
        <div className="form-group">
          <label>Email</label>
          <input type="email" value={userData.email} readOnly />
        </div>
        <div className="form-group">
          <label>Phone Number</label>
          <input type="tel" value={userData.phone} readOnly />
        </div>
        <div className="form-group">
          <label>Passion</label>
          <input type="text" value={userData.passions} readOnly />
        </div>
        <div className="form-group">
          <label>Profession</label>
          <input type="text" value={userData.usertype} readOnly />
        </div>
        <button 
          className="button change-password"
          onClick={handleChangePassword}
        >
          Change Password
        </button>
        <button 
          className="button delete-account"
          onClick={handleDeleteAccount}
        >
          Delete Account
        </button>
      </div>
    </div>
  );

  const SubscriptionDetails = () => (
    <div className="profile-section">
      <div className="form-fields">
        <div className="form-group">
          <label>Subscription Type</label>
          <input type="text" value={userData.subscriptionType} readOnly />
        </div>
        <div className="form-group">
          <label>Subscription Value</label>
          <input type="text" value={userData.subscriptionValue} readOnly />
        </div>
        <div className="form-group">
          <label>Next Renewal Date</label>
          <input type="text" value={userData.renewalDate} readOnly />
        </div>
        <button 
          className="button upgrade-account"
          onClick={handleUpgradeAccount}
        >
          Upgrade Account
        </button>
      </div>
    </div>
  );

  const InstitutionDetails = () => (
    <div className="profile-section">
      <div className="form-fields">
        <div className="form-group">
          <label>Grade</label>
          <input type="text" value={userData.grade} readOnly />
        </div>
        <div className="form-group">
          <label>Institution</label>
          <input type="text" value={userData.institute} readOnly />
        </div>
        <div className="form-group">
          <label>District</label>
          <input type="text" value={userData.district} readOnly />
        </div>
        <div className="form-group">
          <label>State</label>
          <input type="text" value={userData.state} readOnly />
        </div>
        <button 
          className="button upgrade-account"
          onClick={handleUpgradeAccount}
        >
          Update Institute Details
        </button>
      </div>
    </div>
  );

  const renderContent = () => {
    switch (activeSection) {
      case 'personal':
        return <PersonalDetails />;
      case 'subscription':
        return <SubscriptionDetails />;
      case 'institution':
        return <InstitutionDetails />;
      default:
        return <PersonalDetails />;
    }
  };

  return (
    <div className="page-container">
      {/* Sidebar */}
      <div className="sidebar">
        <div 
          className={`sidebar-item ${activeSection === 'personal' ? 'active' : ''}`}
          onClick={() => setActiveSection('personal')}
        >
          <User size={24} />
          <span>Personal Details</span>
        </div>
        <div 
          className={`sidebar-item ${activeSection === 'subscription' ? 'active' : ''}`}
          onClick={() => setActiveSection('subscription')}
        >
          <CreditCard size={24} />
          <span>Subscription</span>
        </div>
        <div 
          className={`sidebar-item ${activeSection === 'institution' ? 'active' : ''}`}
          onClick={() => setActiveSection('institution')}
        >
          <Building size={24} />
          <span>Institution Details</span>
        </div>
      </div>

      {/* Main Content */}
      <main className="main-content">
        <h1>
          {activeSection === 'personal' && 'Personal Details'}
          {activeSection === 'subscription' && 'Subscription Details'}
          {activeSection === 'institution' && 'Institution Details'}
        </h1>
        {renderContent()}
      </main>
    </div>
  );
};

export default AccountInfo;