import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { student_assessment_report } from '../../api'; // Adjust the import path as necessary
import './StudentReport.css';

const ReportComponent = () => {
  const location = useLocation();
  // Assuming `assessmentId` is passed correctly via routing. If it might not be, consider additional error handling.
  const assessmentId = location.state && location.state.assessmentId;
  // const assessmentId = 40;
  
  const [report, setReport] = useState({
    total_score: 0,
    total_marks: 0,
    comments: [],
    topic_scores: []
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!assessmentId) {
      setError('Assessment ID is missing');
      setIsLoading(false);
      return;
    }
    const fetchReport = async () => {
      try {
        const data = await student_assessment_report(assessmentId);
        console.log("Fetched report data:", data);
        console.log("Comments data:", data.comments);
        console.log("Topic scores data:", data.topic_score);
    
        // Then set the report
        setReport({
          total_score: data.total_score,
          total_marks: data.total_marks,
          comments: data.comments ? Object.entries(data.comments).map(([question, feedback]) => ({ question, feedback })) : [],
          topic_scores: data.topic_score ? Object.entries(data.topic_score).map(([topic, { total, correct }]) => ({ topic, total, correct })) : []
        });
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching report data:", error);
        setError(error.toString());
        setIsLoading(false);
      }
    };

    fetchReport();
  }, [assessmentId]);

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="report_container">
      {report && (
        <>
          <div className="report_header">
            <h2>Overall Assessment Report</h2>
          </div>
          <div className="report_metrics">
            <div className="report_metric">
              <h3>Total Score</h3>
              <p className="report_metric-value">{report.total_score} out of {report.total_marks} points</p>
            </div>
          </div>
          <div className="report_questions">
            <h3>Comments on Student Performance</h3>
            {report.comments.length > 0 ? (
              report.comments.map((comment, index) => (
                <div key={index} className="report_question">
                  <h4>Question {index + 1}</h4>
                  <p>{comment.question}</p>
                  <p>{comment.feedback}</p>
                </div>
              ))
            ) : (
              <p>No comments available</p>
            )}
          </div>
          <div>
            <h3>Topic Scores</h3>
            {report.topic_scores.length > 0 ? (
              report.topic_scores.map((score, index) => (
                <div key={index} className="report_metric">
                  <h4>{score.topic}</h4>
                  <p className="report_metric-value">{score.correct} out of {score.total}</p>
                </div>
              ))
            ) : (
              <p>No topic scores available</p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ReportComponent;
