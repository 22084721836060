import React, { useEffect, useState } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import Login from "./pages/Login";
import Register from "./pages/Register";
import TeacherHome from "./pages/TeacherHome";
import SaasHome from "./pages/SaasHome";
import Schedules from "./pages/Schedules";
import LessonPlan from "./pages/LessonPlan";
import MyAccount from "./pages/MyAccount";
import PrivateRoute from "./components/PrivateRoute";
import SaasHeader from "./components/SaasHeader";
import LessonPlanGenerator from "./pages/LessonPlanGenerator";
import QuestionAnswer from "./pages/QuestionAnswer";
import ChatBot from "./pages/ChatBot";
import TeacherHeader from "./components/TeacherHeader";
import StudentHeader from "./components/Student/StudentHeader";
import StudentHome from "./pages/Student/StudentHome";
import ViewAssessment from "./pages/Student/ViewAssessment";
import StudentAssessment from "./pages/Student/StudentAssessment";
import GenerateCourse from "./pages/Student/GenerateCourse";
import StudentReport from "./pages/Student/StudentReport";
import StudentChatbot from "./pages/Student/ChatBot";
import ShortCourse from "./pages/Student/shortCourse";
import TeacherViewAssessment from "./pages/TeacherViewAssessment";
import ExpertActivityAssistant from "./pages/ExpertActivityAssistant";
import Assessment from "./pages/Assessment";
import GenerateAssesment from "./pages/GenerateAssesment";
import Home from "./pages/Home";
import AddStudent from "./pages/AddStudent";
import Study from "./pages/Study";
import StudentStudy from "./pages/Student/Study";
import PendingHeader from "./components/PendingHeader";
import Payment from "./payment";
import { AuthProvider } from "./contexts/AuthContext";
import CryptoJS from "crypto-js";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(
    !!localStorage.getItem("jwtToken")
  );
  const [userType, setUserType] = useState("");
  const navigate = useNavigate();

  // Decryption function
  const decryptData = (encryptedData) => {
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (error) {
      console.error("Failed to decrypt user data", error);
      return {};
    }
  };
  useEffect(() => {
    const encryptedUserData = localStorage.getItem("userData");
    const userData = encryptedUserData ? decryptData(encryptedUserData) : {};
    setIsLoggedIn(!!localStorage.getItem("jwtToken"));
    // console.log(userData.usertype);
    setUserType(userData.usertype || "");
    // console.log("UserType===========", userData.usertype);
  }, [isLoggedIn, navigate]);
  const renderHeader = () => {
    // console.log("userType", userType)
    switch (userType) {
      case "teacher":
        return <TeacherHeader />;
      case "student":
        return <StudentHeader />;
      case "saas":
        return <SaasHeader />;
      case "pending":
        return <PendingHeader />;
      default:
        return <Header />;

      // return <Header />;
    }
  };

  const renderHome = () => {
    console.log("userType from home ", userType);
    switch (userType) {
      case "teacher":
        // console.log("UserType===========", userData.userType);
        return <TeacherHome />;
      case "student":
        return <StudentHome />;
      case "saas":
        return <SaasHome />;
      case "pending":
        return <QuestionAnswer />;
      default:
        // console.log("UserType===========", userData.userType);
        return <Home />;
    }
  };
  return (
    <div className="App">
      {renderHeader()}
      <div className="_main_content_app">
        <Routes>
          <Route path="/" element={<Navigate to="/home" replace />} />
          <Route path="/home" element={renderHome()} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/teacher-home"
            element={
              <PrivateRoute>
                <TeacherHome />
              </PrivateRoute>
            }
          />
          <Route
            path="/student-home"
            element={
              <PrivateRoute>
                <StudentHome />
              </PrivateRoute>
            }
          />
          <Route
            path="/student-study"
            element={
              <PrivateRoute>
                <StudentStudy />
              </PrivateRoute>
            }
          />
          <Route
            path="/student-chatbot"
            element={
              <PrivateRoute>
                <StudentChatbot />
              </PrivateRoute>
            }
          />
          <Route
            path="/GenerateCourse"
            element={
              <PrivateRoute>
                <GenerateCourse />
              </PrivateRoute>
            }
          />
          <Route
            path="/saas-home"
            element={
              <PrivateRoute>
                <SaasHome />
              </PrivateRoute>
            }
          />
          <Route
            path="/shortCourse"
            element={
              <PrivateRoute>
                <ShortCourse />
              </PrivateRoute>
            }
          />
          <Route
            path="/question-answer"
            element={
              <PrivateRoute>
                <QuestionAnswer />
              </PrivateRoute>
            }
          />
          <Route
            path="/schedules"
            element={
              <PrivateRoute>
                <Schedules />
              </PrivateRoute>
            }
          />
          <Route
            path="/lessonplan"
            element={
              <PrivateRoute>
                <LessonPlan />
              </PrivateRoute>
            }
          />
          <Route
            path="/chatbot"
            element={
              <PrivateRoute>
                <ChatBot />
              </PrivateRoute>
            }
          />
          <Route
            path="/lessonplangenerator"
            element={
              <PrivateRoute>
                <LessonPlanGenerator />
              </PrivateRoute>
            }
          />
          <Route
            path="/payment"
            element={
              <PrivateRoute>
                <Payment />
              </PrivateRoute>
            }
          />
          <Route
            path="/StudentReport"
            element={
              <PrivateRoute>
                <StudentReport />
              </PrivateRoute>
            }
          />
          <Route
            path="/account"
            element={
              <PrivateRoute>
                <MyAccount />
              </PrivateRoute>
            }
          />
          <Route
            path="/ExpertActivityAssistant"
            element={
              <PrivateRoute>
                <ExpertActivityAssistant />
              </PrivateRoute>
            }
          />
          <Route
            path="/Study"
            element={
              <PrivateRoute>
                <Study />
              </PrivateRoute>
            }
          />
          <Route
            path="/Assessment"
            element={
              <PrivateRoute>
                <Assessment />
              </PrivateRoute>
            }
          />
          <Route
            path="/GenerateAssesment"
            element={
              <PrivateRoute>
                <GenerateAssesment />
              </PrivateRoute>
            }
          />
          <Route
            path="/ViewAssessment"
            element={
              <PrivateRoute>
                <ViewAssessment />
              </PrivateRoute>
            }
          />
          <Route
            path="/TeacherViewAssessment"
            element={
              <PrivateRoute>
                <TeacherViewAssessment />
              </PrivateRoute>
            }
          />
          <Route
            path="/StudentAssessment"
            element={
              <PrivateRoute>
                <StudentAssessment />
              </PrivateRoute>
            }
          />
          <Route
            path="/AddStudent"
            element={
              <PrivateRoute>
                <AddStudent />
              </PrivateRoute>
            }
          />
          <Route
            path="*"
            element={isLoggedIn ? renderHome() : <Navigate to="/" />}
          />
          {/* <Route
          path="*"
          element={isLoggedIn ? <Navigate to={userType ? `/${userType}-home` : "/home"} /> : <Navigate to="/" />}
        /> */}
          {/* <Route path="*" element={
          <Navigate to={isLoggedIn ? (location.pathname === "/" ? `/${userType}-home` : location.pathname) : "/login"} replace />
        } /> */}
        </Routes>
      </div>
    </div>
  );
}

// export default () => (
//   <AuthProvider>
//     <Router>
//       <App />
//     </Router>
//   </AuthProvider>
// );

const AppWithRouter = () => (
  <AuthProvider>
    <Router>
      <App />
    </Router>
  </AuthProvider>
);

export default AppWithRouter;
