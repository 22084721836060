// src/pages/SaasHome.js
import React from 'react';
import './SaasHome.css';
// import SaasHeader from '../components/SaasHeader';

const SaasHome = () => {
    return (
        <div className="saas-home">
            {/* <SaasHeader /> */}
            <main>
                {/* Empty body */}
            </main>
            <footer>
                {/* Footer content */}
            </footer>
        </div>
    );
};

export default SaasHome;
