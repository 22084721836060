// src/contexts/AuthContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';

const AuthContext = createContext(null);

export function useAuth() {
    return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userType, setUserType] = useState('');

    const encryptData = (data) => {
        const secretKey = process.env.REACT_APP_SECRET_KEY; // Ensure you have this environment variable set securely
        return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
    };
    
    const decryptData = (encryptedData) => {
        const secretKey = process.env.REACT_APP_SECRET_KEY;
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
            const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
            return JSON.parse(decryptedData);
        } catch (error) {
            console.error("Decryption failed:", error);
            return null; // Return null or an empty object depending on how you want to handle failures
        }
    };

    // useEffect(() => {
    //     // Here you would normally fetch this data from localStorage or your state management solution
    //     const userData = JSON.parse(localStorage.getItem('userData'));
    //     if (userData && userData.usertype) {
    //         setIsLoggedIn(true);
    //         setUserType(userData.usertype);
    //     } else {
    //         setIsLoggedIn(false);
    //         setUserType('');
    //     }
    // }, []);
    useEffect(() => {
        const encryptedUserData = localStorage.getItem('userData');
        if (encryptedUserData) {
            const userData = decryptData(encryptedUserData);
            if (userData && userData.usertype) {
                setIsLoggedIn(true);
                setUserType(userData.usertype);
            } else {
                setIsLoggedIn(false);
                setUserType('');
            }
        }
    }, []);

    // const login = (data) => {
    //     localStorage.setItem('userData', JSON.stringify(data));
    //     setIsLoggedIn(true);
    //     setUserType(data.usertype);
    // };
    const login = (data) => {
        const encryptedData = encryptData(data);
        localStorage.setItem('userData', encryptedData);
        setIsLoggedIn(true);
        setUserType(data.usertype);
    };

    const logout = () => {
        localStorage.removeItem('userData');
        setIsLoggedIn(false);
        setUserType('');
    };

    return (
        <AuthContext.Provider value={{ isLoggedIn, userType, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
