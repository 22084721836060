import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./GenerateAssesment.css";
import { fetchGrades, fetchBoards, fetchSubjects, fetchChapters } from "../assesment_api";
// import { getAllEducationalContent, AssesmentRequest } from "../api";
import { AssesmentRequest } from "../api";
import CryptoJS from "crypto-js";

const encryptData = (data) => {
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};

const decryptData = (encryptedData) => {
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error("Decryption failed:", error);
    return null;
  }
};

const generateSessionID = () => {
  return `${new Date().getTime()}-${Math.random().toString(36).substr(2, 9)}`;
};

const Input = ({ id, type = "text", value, onChange, placeholder, readOnly = false }) => (
  <input
    id={id}
    name={id}
    type={type}
    value={value}
    onChange={onChange}
    placeholder={placeholder}
    readOnly={readOnly}
    className="GenerateAssessment_input"
  />
);

const Select = ({ id, value, onChange, required = false, errorMessage = "", children }) => (
  <div className="GenerateAssessment_form-group" data-error={errorMessage}>
    <select
      id={id}
      name={id}
      value={value}
      onChange={onChange}
      required={required}
      className="GenerateAssessment_select"
    >
      {children}
    </select>
  </div>
);

const Label = ({ htmlFor, children }) => (
  <label htmlFor={htmlFor} className="GenerateAssessment_label">
    {children}
  </label>
);

const QuestionPaperForm = () => {
  const [formData, setFormData] = useState({
    grade: "",
    board: "",
    subject: "",
    chapter: [],
    choose: 0,
    description: "",
    blanks: 0,
    question: 0,
    matchfollowing: 0,
    marks_choose: 0,
    marks_blanks: 0,
    marks_question: 0,
    marks_match: 0,
    total_marks: 0,
    timeLimit: 60,
    validity: 7,
    sender: "Assessment",
    sessionID: "",
    chatID: "",
  });

  const [step, setStep] = useState(1);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const navigate = useNavigate();
  const [sessionID] = useState(() => {
    const storedSessionID = localStorage.getItem("assgenID");
    if (storedSessionID) {
      const decryptedSessionID = decryptData(storedSessionID);
      return decryptedSessionID || generateSessionID();
    }
    return generateSessionID();
  });

  const [grades, setGrades] = useState([]);
  const [boards, setBoards] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [chapters, setChapters] = useState([]);


  const [warning, setWarning] = useState("");
  const [educationalContent, setEducationalContent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [assessmentMessages, setAssessmentMessages] = useState(() => {
    const storedMessages = localStorage.getItem("AssesmentMessage");
    return storedMessages ? decryptData(storedMessages) : [];
  });

  // Fetch grades on initial load
  useEffect(() => {
    const getGrades = async () => {
      setIsLoading(true);
      try {
        const gradesData = await fetchGrades();
        setGrades(gradesData);
      } catch (err) {
        setError('Failed to fetch grades');
      } finally {
        setIsLoading(false);
      }
    };

    getGrades();
  }, []);

  // Fetch boards when grade changes
  useEffect(() => {
    const getBoards = async () => {
      if (!formData.gradeNumber) {
        setBoards([]);
        return;
      }

      setIsLoading(true);
      try {
        const boardsData = await fetchBoards(formData.gradeNumber);
        setBoards(boardsData);
        // Reset dependent fields
        setFormData(prev => ({
          ...prev,
          board: '',
          subject: '',
          chapter: []
        }));
      } catch (err) {
        setError('Failed to fetch boards');
      } finally {
        setIsLoading(false);
      }
    };

    getBoards();
  }, [formData.gradeNumber]);

  // Fetch subjects when board changes
  useEffect(() => {
    const getSubjects = async () => {
      if (!formData.gradeNumber || !formData.board) {
        setSubjects([]);
        return;
      }

      setIsLoading(true);
      try {
        const subjectsData = await fetchSubjects(formData.gradeNumber, formData.board);
        setSubjects(subjectsData);
        // Reset dependent fields
        setFormData(prev => ({
          ...prev,
          subject: '',
          chapter: []
        }));
      } catch (err) {
        setError('Failed to fetch subjects');
      } finally {
        setIsLoading(false);
      }
    };

    getSubjects();
  }, [formData.gradeNumber, formData.board]);

  // Fetch chapters when subject changes
  useEffect(() => {
    const getChapters = async () => {
      if (!formData.gradeNumber || !formData.board || !formData.subject) {
        setChapters([]);
        return;
      }

      setIsLoading(true);
      try {
        const chaptersData = await fetchChapters(
          formData.gradeNumber,
          formData.board,
          formData.subject
        );
        setChapters(chaptersData);
        // Reset chapter selection
        setFormData(prev => ({
          ...prev,
          chapter: []
        }));
      } catch (err) {
        setError('Failed to fetch chapters');
      } finally {
        setIsLoading(false);
      }
    };

    getChapters();
  }, [formData.gradeNumber, formData.board, formData.subject]);

  useEffect(() => {
    const encryptedSessionID = encryptData(sessionID);
    localStorage.setItem("assgenID", encryptedSessionID);

    return () => {
      localStorage.removeItem("assgenID");
    };
  }, [sessionID]);

  useEffect(() => {
    const encryptedMessages = encryptData(assessmentMessages);
    localStorage.setItem("AssesmentMessage", encryptedMessages);
  }, [assessmentMessages]);
  
  const renderTabs = () => (
    <div className="GenerateAssessment_tabs">
      <button
        className={`GenerateAssessment_tab ${step === 1 ? 'active' : ''}`}
        onClick={() => setStep(1)}
      >
        Academic
      </button>
      <button
        className={`GenerateAssessment_tab ${step === 2 ? 'active' : ''}`}
        onClick={() => setStep(2)}
      >
        Question Types
      </button>
      <button
        className={`GenerateAssessment_tab ${step === 3 ? 'active' : ''}`}
        onClick={() => setStep(3)}
      >
        Time Limits
      </button>
    </div>
  );

  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setShowDropdown((prev) => !prev);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      const numericFields = [
        "choose", "blanks", "question", "matchfollowing",
        "marks_choose", "marks_blanks", "marks_question", "marks_match",
        "timeLimit", "validity"
      ];
      
      let newValue;
      if (numericFields.includes(name)) {
        newValue = Math.max(0, parseInt(value) || 0);
      } else if (name === "grade") {
        // Extract the grade number when grade is selected
        const gradeNumber = value.replace(/\D/g, ''); // Remove non-digits
        return {
          ...prev,
          grade: value,
          gradeNumber: gradeNumber
        };
      } else {
        newValue = value;
      }
      
      return { ...prev, [name]: newValue };
    });
  }, []);

  const handleChapterChange = useCallback((e) => {
    const { value, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      chapter: checked
        ? [...prev.chapter, value]
        : prev.chapter.filter((chapter) => chapter !== value),
    }));
  }, []);

  useEffect(() => {
    const calculateTotalMarks = () => {
      const total =
        formData.choose * formData.marks_choose +
        formData.blanks * formData.marks_blanks +
        formData.question * formData.marks_question +
        formData.matchfollowing * formData.marks_match;
      setFormData((prev) => ({ ...prev, total_marks: total }));
    };

    calculateTotalMarks();
  }, [
    formData.choose, formData.blanks, formData.question, formData.matchfollowing,
    formData.marks_choose, formData.marks_blanks, formData.marks_question, formData.marks_match,
  ]);

  const storeFormData = useCallback(() => {
    const dataToStore = {
      ...formData,
      chapter: formData.chapter.join(", "),
    };

    const encryptedData = encryptData(dataToStore);
    localStorage.setItem("encryptedFormData", encryptedData);
  }, [formData]);

  useEffect(() => {
    storeFormData();
  }, [formData, storeFormData]);

  const handleNextStep = () => {
    setWarning("");
    setStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowConfirmation(true);
  };

  const handleConfirm = async () => {
    setShowConfirmation(false);
    setIsLoading(true);
    setError(null);

    const newChatID = `assgenID_${generateSessionID()}`;
    const planDetails = {
      ...formData,
      grade: formData.gradeNumber,
      sessionID: sessionID,
      chatID: newChatID,
    };

    try {
      const response = await AssesmentRequest(planDetails);
      const newMessage = {
        text: response.message || `Assessment generated: ${response.details || "Details not available"}`,
        isUser: false,
        timestamp: new Date().toISOString(),
      };
      setAssessmentMessages((prevMessages) => [...prevMessages, newMessage]);
      navigate("/assessment", { state: { questions: newMessage } });
    } catch (err) {
      setError("Failed to generate assessment");
      console.error("AssesmentRequest error:", err);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading && !Object.keys(educationalContent).length) {
    return <div>Loading educational content...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
      const renderStepOne = () => (
        <>
          <div className="GenerateAssessment_form-section">
            <div className="GenerateAssessment_form-row">
              <div className="GenerateAssessment_form-group">
                <Label htmlFor="grade">Grade</Label>
                <Select
                  id="grade"
                  value={formData.grade}
                  onChange={handleInputChange}
                  required
                  errorMessage="Please select a grade"
                >
                  <option value="">Select Grade</option>
                  {grades.map((grade) => (
                    <option key={grade.classid} value={grade.classid}>
                      {grade.name}
                    </option>
                  ))}
                </Select>
              </div>
              <div className="GenerateAssessment_form-group">
                <Label htmlFor="board">Board</Label>
                <Select
                  id="board"
                  value={formData.board}
                  onChange={handleInputChange}
                  required
                  errorMessage="Please select a board"
                >
                  <option value="">Select Board</option>
                  {boards.map((board) => (
                    <option key={board.id} value={board.id}>
                      {board.name}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="GenerateAssessment_form-row">
              <div className="GenerateAssessment_form-group">
                <Label htmlFor="subject">Subject</Label>
                <Select
                  id="subject"
                  value={formData.subject}
                  onChange={handleInputChange}
                  required
                  errorMessage="Please select a subject"
                >
                  <option value="">Select Subject</option>
                  {subjects.map((subject) => (
                    <option key={subject.id} value={subject.id}>
                      {subject.name}
                    </option>
                  ))}
                </Select>
              </div>
              <div className="GenerateAssessment_form-group">
                <Label htmlFor="chapter">Chapters</Label>
                <div className="GenerateAssessment_chapter-dropdown">
                  <button
                    type="button"
                    className="GenerateAssessment_chapter-dropdown-toggle"
                    onClick={toggleDropdown}
                  >
                    Select Chapters ({formData.chapter.length} selected)
                  </button>
                  {showDropdown && (
                    <div className="GenerateAssessment_chapter-dropdown-menu" ref={dropdownRef}>
                      {chapters.map((chapter, index) => (
                        <label
                          key={`${chapter.name}-${index}`}
                          className="GenerateAssessment_chapter-checkbox"
                        >
                          <input
                            type="checkbox"
                            value={chapter.name}
                            checked={formData.chapter.includes(chapter.name)}
                            onChange={handleChapterChange}
                            required={formData.chapter.length === 0}
                          />
                          {`${chapter.name} (${chapter.number})`}
                        </label>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <button 
            type="button" 
            onClick={handleNextStep} 
            className="GenerateAssessment_next-button"
            disabled={!formData.grade || !formData.board || !formData.subject || formData.chapter.length === 0}
          >
            Next
          </button>
        </>
      );

  const renderStepTwo = () => (
    <>
      <div className="GenerateAssessment_form-section">
        <div className="GenerateAssessment_form-row">
          <div className="GenerateAssessment_form-group">
            <Label htmlFor="choose">Number of Choose Correct Questions</Label>
            <Input
              id="choose"
              name="choose"
              type="number"
              value={formData.choose}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="GenerateAssessment_form-group">
            <Label htmlFor="marks_choose">Marks per Choose Correct Question</Label>
            <Input
              id="marks_choose"
              name="marks_choose"
              type="number"
              value={formData.marks_choose}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="GenerateAssessment_form-row">
          <div className="GenerateAssessment_form-group">
            <Label htmlFor="blanks">Number of Fill in the Blanks</Label>
            <Input
              id="blanks"
              name="blanks"
              type="number"
              value={formData.blanks}
              onChange={handleInputChange}
            />
          </div>
          <div className="GenerateAssessment_form-group">
            <Label htmlFor="marks_blanks">Marks per Fill in the Blanks</Label>
            <Input
              id="marks_blanks"
              name="marks_blanks"
              type="number"
              value={formData.marks_blanks}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="GenerateAssessment_form-row">
          <div className="GenerateAssessment_form-group">
            <Label htmlFor="question">Number of Questions</Label>
            <Input
              id="question"
              name="question"
              type="number"
              value={formData.question}
              onChange={handleInputChange}
            />
          </div>
          <div className="GenerateAssessment_form-group">
            <Label htmlFor="marks_question">Marks per Question</Label>
            <Input
              id="marks_question"
              name="marks_question"
              type="number"
              value={formData.marks_question}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="GenerateAssessment_form-row">
          <div className="GenerateAssessment_form-group">
            <Label htmlFor="matchfollowing">Number of Match the Following</Label>
            <Input
              id="matchfollowing"
              name="matchfollowing"
              type="number"
              value={formData.matchfollowing}
              onChange={handleInputChange}
            />
          </div>
          <div className="GenerateAssessment_form-group">
            <Label htmlFor="marks_match">Marks per Match the Following</Label>
            <Input
              id="marks_match"
              name="marks_match"
              type="number"
              value={formData.marks_match}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>
      {warning && <div className="GenerateAssessment_warning-message">{warning}</div>}
      <div className="GenerateAssessment_form-section">
        <Label htmlFor="total_marks">Total Marks</Label>
        <Input
          id="total_marks"
          value={formData.total_marks}
          readOnly
          className="GenerateAssessment_input_auto"
        />
      </div>
      <div className="GenerateAssessment_button-group">
        <button type="button" onClick={handlePrevStep} className="GenerateAssessment_prev-button">
          Previous
        </button>
        <button type="button" onClick={handleNextStep} className="GenerateAssessment_next-button">
          Next
        </button>
      </div>
    </>
  );

  const renderStepThree = () => (
    <>
      <div className="GenerateAssessment_form-section">
        <div className="GenerateAssessment_form-row">
          <div className="GenerateAssessment_form-group">
            <Label htmlFor="timeLimit">Time Limit (minutes)</Label>
            <Input
              id="timeLimit"
              name="timeLimit"
              type="number"
              value={formData.timeLimit}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="GenerateAssessment_form-group">
            <Label htmlFor="validity">Exam Validity (days)</Label>
            <Input
              id="validity"
              name="validity"
              type="number"
              value={formData.validity}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>
      </div>
      <div className="GenerateAssessment_button-group">
        <button type="button" onClick={handlePrevStep} className="GenerateAssessment_prev-button">
          Previous
        </button>
        <button type="submit" className="GenerateAssessment_submit-button">
          Submit
        </button>
      </div>
    </>
  );

  const renderConfirmationPopup = () => (
    <div className="GenerateAssessment_confirmation-popup">
      <h2>Confirm Assessment Details</h2>
      <div className="GenerateAssessment_confirmation-content">
        <p><strong>Grade:</strong> {formData.grade}</p>
        <p><strong>Board:</strong> {formData.board}</p>
        <p><strong>Subject:</strong> {formData.subject}</p>
        <p><strong>Chapters:</strong> {formData.chapter.join(", ")}</p>
        <p><strong>Total Questions:</strong> {formData.choose + formData.blanks + formData.question + formData.matchfollowing}</p>
        <p><strong>Total Marks:</strong> {formData.total_marks}</p>
        <p><strong>Time Limit:</strong> {formData.timeLimit} minutes</p>
        <p><strong>Validity:</strong> {formData.validity} days</p>
      </div>
      <div className="GenerateAssessment_confirmation-buttons">
        <button onClick={() => setShowConfirmation(false)} className="GenerateAssessment_cancel-button">
          Cancel
        </button>
        <button onClick={handleConfirm} className="GenerateAssessment_confirm-button">
          Confirm
        </button>
      </div>
    </div>
  );

  return (
    <div className="GenerateAssessment_form-container">
      {renderTabs()}
      <form onSubmit={handleSubmit} className="GenerateAssessment_form">
        {step === 1 && renderStepOne()}
        {step === 2 && renderStepTwo()}
        {step === 3 && renderStepThree()}
      </form>
      {error && <div className="GenerateAssessment_error-message">{error}</div>}
      {showConfirmation && renderConfirmationPopup()}
    </div>
  );
};

export default QuestionPaperForm;