// ButtonLink.js

import React from "react";
import PropTypes from "prop-types";
import "./CustomButton.css";

const CustomButton = ({
  text,
  className,
  isDisabled,
  type,
  onClickfn,
  params,
}) => {
  return (
    <button
      type={type}
      className={className}
      onClick={onClickfn ? () => onClickfn(params) : () => {}}
      disabled={isDisabled}
    >
      {text}
    </button>
  );
};

CustomButton.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClickfn: PropTypes.func,
};

export default CustomButton;
