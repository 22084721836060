import React, { useState, useEffect, useMemo, useCallback } from "react";
import { MessageSquare, Upload } from "lucide-react";
import "./Study.css";
import {
  getAllEducationalContent,
  getAllUserContent,
  getDocument,
  studentChatbot,
  uploadDocument,
  getAllUserDocumentURL,
} from "../../api";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import CryptoJS from "crypto-js";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.4.168/pdf.worker.min.mjs`;

const encryptData = (data) => {
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};

const decryptData = (encryptedData) => {
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error("Decryption failed:", error);
    return null;
  }
};

const generateSessionId = () =>
  `${new Date().getTime()}-${Math.random().toString(36).substr(2, 9)}`;

const generateSessionName = (lastMessage) => {
  return lastMessage
    ? `${lastMessage.substring(0, 20)}${lastMessage.length > 20 ? "..." : ""}`
    : "New Chat Session";
};

const Study = () => {
  const [formData, setFormData] = useState({
    subject: "",
    grade: "",
    board: "",
    selectedChapter: "",
  });
  const [UserformData, setUserformData] = useState({
    filename: "",
  });
  const [doctype, setDoctype] = useState("");
  const [currentForm, setCurrentForm] = useState("doctype");
  const [educationalContent, setEducationalContent] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  // const [scale, setScale] = useState(1.0);
  const [scale, setScale] = useState(0.5);
  const [chatOpen, setChatOpen] = useState(false);
  const [pdfError, setPdfError] = useState(null);
  // const [pdfPages, setPdfPages] = useState([]);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  // Add state to manage the width of the PDF viewer
  const [pdfReaderWidth, setPdfReaderWidth] = useState("100%");

  const [uploadedFile, setUploadedFile] = useState(null);
  const [popupMessage, setPopupMessage] = useState("");
  const [userContent, setUserContent] = useState({});

  const sessionId =
    decryptData(localStorage.getItem("StudentchatSessionId")) ||
    generateSessionId();
  const [userDetails, setUserDetails] = useState({
    firstname: "",
    email: "",
    username: "",
  });
  const [StudentsessionHistory, setStudentsessionHistory] = useState(
    () => decryptData(localStorage.getItem("StudentsessionHistory")) || []
  );
  const [sessionID] = useState(() => localStorage.getItem("sessionID")) || [];

  const saveMessagesToLocalStorage = useCallback((sessionId, messages) => {
    localStorage.setItem(`Studentmessages_${sessionId}`, encryptData(messages));
  }, []);

  const loadMessagesFromLocalStorage = useCallback((sessionId) => {
    return (
      decryptData(localStorage.getItem(`Studentmessages_${sessionId}`)) || []
    );
  }, []);

  useEffect(() => {
    localStorage.setItem("StudentchatSessionId", encryptData(sessionId));
    const storedData = decryptData(localStorage.getItem("userData"));
    // console.log(storedData)
    if (storedData) {
      setUserDetails({
        firstname: storedData.firstname,
        email: storedData.email,
        username: storedData.username,
      });
    }
  }, [sessionId]);
  // console.log(messages);

  useEffect(() => {
    const lastMessage = messages[messages.length - 1]?.message;
    const timestamp = new Date().toISOString();
    if (lastMessage && !StudentsessionHistory.find((s) => s.id === sessionId)) {
      const newSessionName = generateSessionName(lastMessage);
      const newHistory = [
        ...StudentsessionHistory,
        { id: sessionId, name: newSessionName, timestamp },
      ];
      setStudentsessionHistory(newHistory);
      localStorage.setItem("StudentsessionHistory", encryptData(newHistory));
    }
  }, [messages, StudentsessionHistory, sessionId]);

  useEffect(() => {
    setMessages(loadMessagesFromLocalStorage(sessionId));
  }, [sessionId, loadMessagesFromLocalStorage]);

  const handleDoctypeChange = (e) => {
    setDoctype(e.target.value);
  };

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleNextClick = async () => {
    setIsLoading(true);
    try {
      let response;
      if (doctype === "Preloaded") {
        response = await getAllEducationalContent();
        if (!response.ok) {
          throw new Error(`Server responded with status: ${response.status}`);
        }
        console.log("Response for Educational Content----", response);
        const data = await response.json();
        // console.log(typeof data);
        setEducationalContent(JSON.parse(data));
      } else if (doctype === "Your Doc") {
        const storedUserData = decryptData(localStorage.getItem("userData"));
        response = await getAllUserContent(storedUserData.username);
        if (!response.ok) {
          throw new Error(`Server responded with status: ${response.status}`);
        }
        const data = await response.json();
        console.log(typeof data);
        setUserContent(data);
      }
      setCurrentForm(doctype === "Preloaded" ? "preloaded" : "yourDoc");
    } catch (error) {
      console.error("Error fetching data:", error);
      // Set an error state or a message here to display to the user if needed
    }
    setIsLoading(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;

    const storedUserData = decryptData(localStorage.getItem("userData"));
    const selectedChapterDetails = formData.selectedChapter && chapters.find(chapter => chapter.chaptername === formData.selectedChapter);
    
    const StudentMessageData = {
      message: newMessage,
      sender: "studybuddy",
      // timestamp: new Date().toLocaleTimeString(),
      firstname: storedUserData?.userData?.firstname || "Anonymous",
      username: userDetails.username || "anonymous",
      email: storedUserData?.userData?.email || "no-email@example.com",
      sessionID: sessionID,
      chatID: `Studentmessages_${sessionId}`,
      chapterName: selectedChapterDetails ? selectedChapterDetails.chaptername : "",
      chapterNumber: selectedChapterDetails ? selectedChapterDetails.chapternumber : "",
      subject: formData.subject,
      class: formData.grade,
      board: formData.board,
      usertype: storedUserData.usertype,
      timestamp: new Date().toISOString()
    };
  setMessages(prevMessages => [...prevMessages, { ...StudentMessageData, sender: 'student' }]); // Update UI immediately with user message
  setNewMessage("");  // Clear input field

  try {
    const responseData = await studentChatbot(StudentMessageData);
    if (responseData && responseData.message) {
      const botMessage = {
        message: responseData.message,
        sender: 'bot',
        timestamp: new Date().toLocaleTimeString(),
      };
      setMessages(prevMessages => [...prevMessages, botMessage]);
    }
  } catch (error) {
    console.error("Error sending message:", error);
    setMessages(prevMessages => [
      ...prevMessages,
      {
        message: "Sorry, there was an error processing your request.",
        sender: "bot",
        timestamp: new Date().toLocaleTimeString(),
      },
    ]);
  }
};


  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
      event.preventDefault();
    }
  };

  const handleBackClick = () => {
    setCurrentForm("doctype");
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setPdfError(null);
    try {
      const response = await getDocument(formData);
      if (response && response.url) {
        // Fetch the PDF as a blob
        const pdfResponse = await fetch(response.url, {
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
          },
        });
        if (!pdfResponse.ok) throw new Error("Failed to fetch PDF");
        const pdfBlob = await pdfResponse.blob();
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfUrl(pdfUrl);
        setFormSubmitted(true);
      } else {
        throw new Error("Invalid document data received");
      }
    } catch (error) {
      console.error("Error fetching document:", error);
      setPdfError("Failed to load the document. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleFormSubmit1 = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setPdfError(null);
    try {
      const response = await getAllUserDocumentURL(UserformData);
      if (response && response.url) {
        // Fetch the PDF as a blob
        const pdfResponse = await fetch(response.url, {
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
          },
        });
        if (!pdfResponse.ok) throw new Error("Failed to fetch PDF");
        const pdfBlob = await pdfResponse.blob();
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfUrl(pdfUrl);
        setFormSubmitted(true);
      } else {
        throw new Error("Invalid document data received");
      }
    } catch (error) {
      console.error("Error fetching document:", error);
      setPdfError("Failed to load the document. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const grades = useMemo(
    () =>
      Object.keys(educationalContent).map((key) => ({
        classid: key,
        name: `Grade ${key}`,
      })),
    [educationalContent]
  );

  const boards = useMemo(
    () =>
      formData.grade
        ? Object.keys(educationalContent[formData.grade]).map((key) => ({
            id: key,
            name: key,
          }))
        : [],
    [formData.grade, educationalContent]
  );

  const subjects = useMemo(
    () =>
      formData.grade && formData.board
        ? Object.keys(educationalContent[formData.grade][formData.board]).map(
            (key) => ({ id: key, name: key })
          )
        : [],
    [formData.grade, formData.board, educationalContent]
  );

  const chapters = useMemo(
    () =>
      formData.grade && formData.board && formData.subject
        ? educationalContent[formData.grade][formData.board][formData.subject]
        : [],
    [formData.grade, formData.board, formData.subject, educationalContent]
  );

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check if the file size is greater than 2GB (2 * 1024 * 1024 * 1024 bytes)
      if (file.size > 2147483648) {
        alert("File size should not exceed 2GB.");
        return;
      }
      setUploadedFile(file);
    }
  };

  const handleUploadSubmit = async (e) => {
    e.preventDefault();
    if (!uploadedFile) {
      alert("Please select a file to upload.");
      return;
    }
    setIsLoading(true);
    setPdfError(null);

    try {
      const formData = new FormData();
      formData.append("file", uploadedFile);
      const encryptedUserData = localStorage.getItem("userData");
      const userDetails = encryptedUserData
        ? decryptData(encryptedUserData)
        : {};
      formData.append("username", userDetails.username);
      const response = await uploadDocument(formData);
      if (response && response.message) {
        setPopupMessage(response.message); // Display server response message
        setTimeout(() => setPopupMessage(""), 5000); // Automatically hide the message after 5 seconds
      } else {
        throw new Error("Invalid document data received");
      }
    } catch (error) {
      console.error("Error uploading document:", error);
      setPdfError("Failed to upload the document. Please try again.");
      setPopupMessage(error.message); // Display error message from the catch block
    } finally {
      setIsLoading(false);
    }
  };

  const renderForm = () => {
    switch (currentForm) {
      case "doctype":
        return (
          <div className="study_form-container">
            <select
              id="doctype"
              onChange={handleDoctypeChange}
              className="study_dropdown"
            >
              <option value="">Select Doctype</option>
              <option value="Preloaded">Preloaded</option>
              <option value="Your Doc">Your Doc</option>
            </select>
            <button onClick={handleNextClick} className="study_button">
              Next
            </button>
          </div>
        );
      case "preloaded":
        return (
          <form onSubmit={handleFormSubmit} className="study_form-container">
            <select
              name="grade"
              className="study_dropdown"
              value={formData.grade}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Grade</option>
              {grades.map((grade) => (
                <option key={grade.classid} value={grade.classid}>
                  {grade.name}
                </option>
              ))}
            </select>
            <select
              name="board"
              value={formData.board}
              onChange={handleInputChange}
              required
              className="study_dropdown"
            >
              <option value="">Select Board</option>
              {boards.map((board) => (
                <option key={board.id} value={board.id}>
                  {board.name}
                </option>
              ))}
            </select>
            <select
              name="subject"
              value={formData.subject}
              onChange={handleInputChange}
              required
              className="study_dropdown"
            >
              <option value="">Select Subject</option>
              {subjects.map((subject) => (
                <option key={subject.id} value={subject.id}>
                  {subject.name}
                </option>
              ))}
            </select>
            <select
              name="selectedChapter"
              value={formData.selectedChapter}
              onChange={handleInputChange}
              required
              className="study_dropdown"
            >
              <option value="">Select Chapter</option>
              {chapters.map((chapter, index) => (
                <option
                  key={`${chapter.chaptername}-${index}`}
                  value={chapter.chaptername}
                >{`${chapter.chaptername} (${chapter.chapternumber})`}</option>
              ))}
            </select>
            <button type="submit" className="study_button">
              Submit
            </button>
            <button
              type="button"
              onClick={handleBackClick}
              className="study_button study_button-back"
            >
              Back
            </button>
          </form>
        );
      case "yourDoc":
        return (
          <form onSubmit={handleFormSubmit} className="study_form-container">
            <div className="study_file-upload">
              <input
                type="file"
                onChange={handleFileUpload}
                accept=".pdf"
                id="file-upload"
                className="study_file-input"
              />
              <label htmlFor="file-upload" className="study_file-label">
                <Upload size={24} />
                <span>
                  {uploadedFile ? uploadedFile.name : "Choose a file"}
                </span>
              </label>
            </div>
            <button
              type="submit"
              className="study_button"
              onClick={handleUploadSubmit}
            >
              Upload and Submit
            </button>
            <select className="study_dropdown">
              <option value="">Select Your Document</option>
              {userContent.map((doc, index) => (
                <option key={index} value={doc.filename}>
                  {doc.filename}
                </option>
              ))}
            </select>
            <button
              type="submit"
              className="study_button"
              onClick={handleFormSubmit1}
            >
              Submit
            </button>
            <button
              type="button"
              onClick={handleBackClick}
              className="study_button study_button-back"
            >
              Back
            </button>
          </form>
        );
      default:
        return null;
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPdfError(null);
    // setPdfPages(Array.from(new Array(numPages), (el, index) => index + 1));
  };

  const onDocumentLoadError = (error) => {
    console.error("Error loading PDF:", error);
    setPdfError("Failed to load the PDF. Please try again or contact support.");
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) =>
      Math.max(1, Math.min(prevPageNumber + offset, numPages))
    );
  };

  const handleZoom = (delta) => {
    setScale((prevScale) => Math.max(0.5, Math.min(prevScale + delta, 2.0)));
  };

  const toggleChat = () => {
    setChatOpen(!chatOpen);
    setPdfReaderWidth(chatOpen ? "100%" : "60%");  // Adjust these widths as needed
  };

  const renderChatPopup = () => (
    <div className="chat-popup">
      <div className="chat-header">
        <h2>AIALA</h2>
        <button onClick={toggleChat}>Close</button>
      </div>
      <div className="chat-messages">
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`message ${
              msg.sender === "student" ? "user-message" : "bot-message"
            }`}
          >
            <p>
              <strong>{msg.sender === "student" ? "You" : "Bot"}:</strong>{" "}
              {msg.message}
            </p>
            <small>{msg.timestamp}</small>
          </div>
        ))}
      </div>
      <div className="chat-input">
        <input
          type="text"
          placeholder="Type a message..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <button onClick={handleSendMessage}>Send</button>
      </div>
    </div>
  );

  return (
    <div className="study_main-container">
      {popupMessage && <div className="popup">{popupMessage}</div>}
      {!formSubmitted && (
        <div className="study_form-wrapper">{renderForm()}</div>
      )}
      {formSubmitted && (
        <div
          className="study_pdf-reader"
          style={{ width: pdfReaderWidth, height: windowSize.height }}
          // style={{ width: windowSize.width, height: windowSize.height }}
        >
          <h2 className="study_section-title">PDF Document Reader</h2>
          <div className="study_pdf-controls">
            <button onClick={() => changePage(-1)} disabled={pageNumber <= 1}>
              Previous
            </button>
            <button
              onClick={() => changePage(1)}
              disabled={pageNumber >= numPages}
            >
              Next
            </button>
            <button onClick={() => handleZoom(0.1)}>Zoom In</button>
            <button onClick={() => handleZoom(-0.1)}>Zoom Out</button>
          </div>
          {isLoading ? (
            <p>Loading PDF...</p>
          ) : pdfError ? (
            <p className="study_error-message">{pdfError}</p>
          ) : (
            <div className="study_pdf-container">
              <Document
                file={pdfUrl}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={onDocumentLoadError}
                className="study_pdf-document"
              >
                <Page
                  pageNumber={pageNumber}
                  scale={scale}
                  className="study_pdf-page"
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  width={windowSize.width * 0.9}
                />
              </Document>
            </div>
          )}
          {numPages && (
            <p>
              Page {pageNumber} of {numPages}
            </p>
          )}
          <div className="study_pdf-controls">
            <button onClick={() => changePage(-1)} disabled={pageNumber <= 1}>
              Previous
            </button>
            <button
              onClick={() => changePage(1)}
              disabled={pageNumber >= numPages}
            >
              Next
            </button>
            <button onClick={() => handleZoom(0.1)}>Zoom In</button>
            <button onClick={() => handleZoom(-0.1)}>Zoom Out</button>
          </div>
        </div>
      )}
      <button className="chat-button" onClick={toggleChat}>
        <MessageSquare size={24} color="white" />
      </button>
      {chatOpen && renderChatPopup()}
    </div>
  );
};

export default Study;
