import React from 'react';
import { Send, PlusCircle, Paperclip, Smile } from 'lucide-react';
import './Study.css';

const Study = () => {
  const messages = [
    { id: 1, text: "Hi, I need help with my account.", sender: "user" },
    { id: 2, text: "Sure! What seems to be the issue?", sender: "bot" },
    { id: 3, text: "I can't log in.", sender: "user" },
    { id: 4, text: "Have you tried resetting your password?", sender: "bot" },
    { id: 5, text: "Yes, but it didn't work.", sender: "user" },
    { id: 6, text: "Let me check that for you. One moment please.", sender: "bot" },
    { id: 7, text: "It seems like there's an issue with your account. Please contact support.", sender: "bot" },
    { id: 8, text: "Okay, thank you.", sender: "user" },
    { id: 9, text: "You're welcome! Is there anything else I can help with?", sender: "bot" },
    { id: 10, text: "No, that's all.", sender: "user" },
  ];

  return (
    <div className="app-container">
      <div className="pdf-reader">
        <h2 className="section-title">PDF Document Reader</h2>
        <div className="pdf-content">
          <p>PDF content would be displayed here</p>
        </div>
      </div>

      <div className="chat-interface">
        <h2 className="section-title">Chatbot Interface</h2>
        <div className="messaging-area">
          {messages.map((message) => (
            <div key={message.id} className={`message ${message.sender}`}>
              <div className="message-content">
                {message.text}
              </div>
            </div>
          ))}
        </div>
        <div className="text-input-area">
          <div className="text-input-container">
            <button className="icon-button">
              <PlusCircle size={24} />
            </button>
            <input
              type="text"
              placeholder="Type a message"
              className="message-input"
            />
            <button className="icon-button">
              <Paperclip size={24} />
            </button>
            <button className="icon-button">
              <Smile size={24} />
            </button>
            <button className="send-button">
              <Send size={24} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Study;