/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import "./LessonPlanGenerator.css";
import {
  getAllEducationalContent,
  generatelessonplan,
  updateLessonPlan,
} from "../api";
import Markdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import CryptoJS from "crypto-js";
import LoadingAnimation from "../components/LoadingAnimation";

const encryptData = (data) => {
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};

const decryptData = (encryptedData) => {
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error("Decryption failed:", error);
    return null;
  }
};

const generateSessionID = () => {
  return `${new Date().getTime()}-${Math.random().toString(36).substr(2, 9)}`;
};

const LessonPlanGenerator = () => {
  const [formData, setFormData] = useState({
    activityName: "",
    subject: "",
    grade: "",
    board: "",
    startDate: "",
    endDate: "",
    selectedChapter: "",
    hoursPerDay: "",
    description: "",
    sessionID: "",
    chatID: "",
  });


  // Retrieve sessionID from localStorage or generate a new one if not present
  // const [sessionID, setSessionID] =
  //   useState(() => decryptData(localStorage.getItem("sessionID"))) || [];
  const [sessionID] = useState(() => localStorage.getItem("sessionID")) || [];

    const sessionIdRef = useRef(localStorage.getItem("lpgenID") ? decryptData(localStorage.getItem("lpgenID")) : generateSessionID());
    const newSessionID = useRef(""); // Ref to hold the new session ID

  const [educationalContent, setEducationalContent] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [chatMessages, setChatMessages] = useState(() => {
    const encryptedMessages = localStorage.getItem("chatMessages");
    return encryptedMessages ? decryptData(encryptedMessages) : [];
  });
  const [inputMessage, setInputMessage] = useState("");
  const [showForm, setShowForm] = useState(true);
  const [showChat, setShowChat] = useState(false);
  const [enableButtons, setEnableButtons] = useState(false);
  const [enableChatInput, setEnableChatInput] = useState(false);
  const [dateErrors, setDateErrors] = useState({
    startDate: false,
    endDate: false,
  });
  const [errorMsg, setErrorMsg] = useState("");
  const chatEndRef = useRef(null);
  const formRef = useRef(null);

  useEffect(() => {
    window.onbeforeunload = () => {
      localStorage.removeItem("lpgenID");
      localStorage.removeItem("chatMessages");
    };

    setIsLoading(true);
    getAllEducationalContent().then((data) => {
      setEducationalContent(JSON.parse(data));
      setIsLoading(false);
    });

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getAllEducationalContent().then((data) => {
      setEducationalContent(JSON.parse(data));
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.style.opacity = "1";
    }
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatMessages]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    // Date validation on-the-fly
    if (name === "startDate" || name === "endDate") {
      validateDates(formData.startDate, formData.endDate, name, value);
    }
  };

  const validateDates = (start, end, changedField, newValue) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const newStartDate =
      changedField === "startDate" ? new Date(newValue) : new Date(start);
    const newEndDate =
      changedField === "endDate" ? new Date(newValue) : new Date(end);

    let errors = { startDate: false, endDate: false };
    let message = "";

    if (newStartDate < today) {
      errors.startDate = true;
      message = "Start date cannot be in the past.";
    }
    if (newEndDate < today) {
      errors.endDate = true;
      message = "End date cannot be in the past.";
    }
    if (newStartDate > newEndDate) {
      errors.startDate = true;
      errors.endDate = true;
      message = "End date cannot be before start date.";
    }

    setDateErrors(errors);
    setErrorMsg(message);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowForm(false);
    setShowChat(true);

    // Final validation before submitting
    validateDates(formData.startDate, formData.endDate, "finalCheck", null);
    if (!dateErrors.startDate && !dateErrors.endDate) {
      console.log("Submitting form...");
      console.log("Session ID used:", sessionID);
      
      newSessionID.current = generateSessionID(); // Update ref value
    sessionIdRef.current = newSessionID.current; // Optionally update this ref if needed
    localStorage.setItem("lpgenID", encryptData(newSessionID.current));

      setIsLoading(true);
      console.log("Session ID from the LP GEN", newSessionID.current);
      const planDetails = {
        activityName: formData.activityName,
        grade: formData.grade,
        board: formData.board,
        subject: formData.subject,
        startDate: formData.startDate,
        endDate: formData.endDate,
        chapter: formData.selectedChapter,
        hoursPerDay: formData.hoursPerDay,
        sender: "Lesson_plan",
        sessionID: sessionID, // Use the new session ID for this session
        description: formData.description,
        chatID: `lpgenID_${newSessionID.current}`, // Unique chat ID using the session ID
      };
      generatelessonplan(planDetails)
        .then((response) => {
          console.log("Lesson plan generated:", response);
          const responseMessage =
            response.message ||
            `Lesson plan generated: ${
              response.details || "Details not available"
            }`;
          const newMessages = [{ text: responseMessage, isUser: false }];
          setChatMessages(newMessages);
          localStorage.setItem("chatMessages", encryptData(newMessages));
          setIsLoading(false);
          setEnableButtons(true);
        })
        .catch((error) => {
          console.error("Failed to generate lesson plan:", error);
          setIsLoading(false);
          setEnableButtons(false);
        });
    } else {
      console.log("Fix errors before submitting.");
    }
    document
      .querySelector("._lesson-plan-form-container")
      .classList.add("hidden");
    setTimeout(() => {
      document
        .querySelector("._lessson_chat-container")
        .classList.add("visible");
    }, 500);


    setIsLoading(true);
    const planDetails = {
      activityName: formData.activityName,
      grade: formData.grade,
      board: formData.board,
      subject: formData.subject,
      startDate: formData.startDate,
      endDate: formData.endDate,
      chapter: formData.selectedChapter,
      hoursPerDay: formData.hoursPerDay,
      sender: "Lesson_plan",
      sessionID: sessionID,
      description: formData.description,
      chatID: `lpgenID_${newSessionID}`,
    };
    setIsLoading(true);
    generatelessonplan(planDetails)
      .then((response) => {
        const responseMessage =
          response.message ||
          `Lesson plan generated: ${
            response.details || "Details not available"
          }`;
        const newMessages = [
          ...chatMessages,
          { text: responseMessage, isUser: false },
        ];
        setChatMessages(newMessages);
        localStorage.setItem("chatMessages", encryptData(newMessages));
        setEnableButtons(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Failed to generate lesson plan:", error);
        // setIsLoading(false);
        // setChatMessages(prev => [...prev, { text: 'Failed to generate lesson plan due to an error.', isUser: false }]);
        const newMessages = [
          ...chatMessages,
          {
            text: "Failed to generate lesson plan due to an error.",
            isUser: false,
          },
        ];
        setChatMessages(newMessages);
        localStorage.setItem("chatMessages", encryptData(newMessages));
        setEnableButtons(false);
      });
  };

  const handleChatSubmit = () => {
    setIsLoading(true);

    if (inputMessage.trim()) {
      const newMessages = [
        ...chatMessages,
        { text: inputMessage, isUser: true },
      ];
      setChatMessages(newMessages);
      localStorage.setItem("chatMessages", encryptData(newMessages));

      const planDetails = {
        activityName: formData.activityName,
        grade: formData.grade,
        board: formData.board,
        subject: formData.subject,
        startDate: formData.startDate,
        endDate: formData.endDate,
        chapter: formData.selectedChapter,
        hoursPerDay: formData.hoursPerDay,
        sender: "Lesson_plan",
        sessionID: sessionID,
        description: inputMessage,
        chatID: `lpgenID_${newSessionID}`,
      };
      generatelessonplan(planDetails)
        .then((response) => {
          console.log("Clarification and plan details sent:", response);
          const responseMessage =
            response.message || "Clarification request submitted successfully.";
          newMessages.push({ text: responseMessage, isUser: false });
          setChatMessages(newMessages);
          localStorage.setItem("chatMessages", encryptData(newMessages));
          setInputMessage("");
          setIsLoading(false);
          setEnableButtons(true);
        })
        .catch((error) => {
          console.error("Failed to send clarification:", error);
          setIsLoading(false);
          setEnableButtons(false);
        });
    }
  };

  const handleNeedClarification = () => {
    setEnableChatInput(true);
    setEnableButtons(false);
  };

  const handleConfirm = () => {
    setEnableChatInput(false);
    setEnableButtons(true);
    setIsLoading(true);
    // Assuming the last received system message contains the details needed for the update
    const lastSystemMessage = chatMessages.filter((msg) => !msg.isUser).pop();
    const encryptedUserData = localStorage.getItem("userData");
    const userDetails = encryptedUserData ? decryptData(encryptedUserData) : {};
    console.log("User Details:", userDetails.username);
    console.log("Last System Message:", lastSystemMessage);

    const updateDetails = {
      activityName: formData.activityName,
      sessionID: sessionID,
      chatID: `lpgenID_${newSessionID}`,
      username: userDetails.username,
      grade: formData.grade,
      subject: formData.subject,
      sender: "Lesson_plan",
      board: formData.board,
      chapter: formData.selectedChapter,
      startDate: new Date(formData.startDate).toISOString(),
      endDate: new Date(formData.endDate).toISOString(),
      lessonplan: lastSystemMessage
        ? lastSystemMessage.text
        : "No last response available",
    };

    updateLessonPlan(updateDetails)
      .then((response) => {
        console.log("Update sent:", response);
        // Handle response here
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Failed to update lesson plan:", error);
        // Handle error here
      });
  };

  const handleFocus = (e) => {
    e.target.parentNode.classList.add("input-focus");
  };

  const handleBlur = (e) => {
    e.target.parentNode.classList.remove("input-focus");
  };

  const grades = Object.keys(educationalContent).map((key) => ({
    classid: key,
    name: `Grade ${key}`,
  }));
  const boards = formData.grade
    ? Object.keys(educationalContent[formData.grade]).map((key) => ({
        id: key,
        name: key,
      }))
    : [];
  const subjects =
    formData.grade && formData.board
      ? Object.keys(educationalContent[formData.grade][formData.board]).map(
          (key) => ({ id: key, name: key })
        )
      : [];
  const chapters =
    formData.grade && formData.board && formData.subject
      ? educationalContent[formData.grade][formData.board][formData.subject]
      : [];

  return (
    <div className={`advanced-lesson-plan-generator`}>
      {isLoading && <LoadingAnimation />}
      <div className="_main-content">
        {showForm && (
          <div className="_lesson-plan-form-container">
            <h2 className="form-title">Generate Your Lesson Plan</h2>
            <form onSubmit={handleSubmit} className="lesson-plan-form">
              <div className="row full-width">
                <input
                  type="text"
                  className="lessonPlan_input"
                  name="activityName"
                  placeholder="Suggest A Valid Unique Name...."
                  value={formData.activityName}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="row">
                <div className="column">
                  <select
                    name="grade"
                    className="lessonPlan_input_select"
                    value={formData.grade}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Grade</option>
                    {grades.map((grade) => (
                      <option key={grade.classid} value={grade.classid}>
                        {grade.name}
                      </option>
                    ))}
                  </select>
                  <select
                    name="subject"
                    value={formData.subject}
                    onChange={handleInputChange}
                    required
                    className="lessonPlan_input_select"
                  >
                    <option value="">Select Subject</option>
                    {subjects.map((subject) => (
                      <option key={subject.id} value={subject.id}>
                        {subject.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="column">
                  <select
                    name="board"
                    value={formData.board}
                    onChange={handleInputChange}
                    required
                    className="lessonPlan_input_select"
                  >
                    <option value="">Select Board</option>
                    {boards.map((board) => (
                      <option key={board.id} value={board.id}>
                        {board.name}
                      </option>
                    ))}
                  </select>
                  <select
                    name="selectedChapter"
                    value={formData.selectedChapter}
                    onChange={handleInputChange}
                    required
                    className="lessonPlan_input_select"
                  >
                    <option value="">Select Chapter</option>
                    {chapters.map((chapter, index) => (
                      <option
                        key={`${chapter.chaptername}-${index}`}
                        value={chapter.chaptername}
                      >{`${chapter.chaptername} (${chapter.chapternumber})`}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row">
                <input
                  type="date"
                  name="startDate"
                  value={formData.startDate}
                  onChange={handleInputChange}
                  required
                />
                <input
                  type="date"
                  name="endDate"
                  value={formData.endDate}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="row">
                <select
                  name="hoursPerDay"
                  value={formData.hoursPerDay}
                  onChange={handleInputChange}
                  required
                  className="lessonPlan_input_select"
                >
                  <option value="">Hours per Day</option>
                  {[...Array(10)].map((_, i) => (
                    <option key={i} value={(i + 1) * 0.5}>
                      {(i + 1) * 0.5} Hours
                    </option>
                  ))}
                </select>
              </div>
              <div className="row">
                <textarea
                  name="description"
                  placeholder="Specific Topic"
                  value={formData.description}
                  onChange={handleInputChange}
                  className="lessonPlan_input_textarea"
                ></textarea>
              </div>
              {errorMsg && <div className="error-message">{errorMsg}</div>}
              <button
                type="submit"
                className="generate-button"
                disabled={isLoading}
              >
                {isLoading ? "Generating..." : "Generate Lesson Plan"}
              </button>
            </form>
          </div>
        )}
        {showChat && (
          <div className="_lessson_chat-container">
            <div className="_chat-messages">
              {chatMessages.map((msg, index) => (
                <div
                  key={index}
                  className={`_message_ ${msg.isUser ? "user" : "system"}`}
                >
                  <Markdown
                    children={msg.text}
                    // components={{
                    //   code({ node, inline, className, children, ...props }) {
                    //     const match = /language-(\w+)/.exec(className || "");
                    //     return !inline && match ? (
                    //       <SyntaxHighlighter
                    //         style={dark}
                    //         language={match[1]}
                    //         PreTag="div"
                    //         {...props}
                    //       >
                    //         {String(children).replace(/\n$/, "<br>")}
                    //       </SyntaxHighlighter>
                    //     ) : (
                    //       <code className={className} {...props}>
                    //         {children}
                    //       </code>
                    //     );
                    //   },
                    // }}
                  />
                </div>
              ))}
              <div ref={chatEndRef} />
            </div>
            <div className="button-container">
              <button disabled={!enableButtons} onClick={handleConfirm}>
                Confirm
              </button>
              <button
                disabled={!enableButtons}
                onClick={handleNeedClarification}
              >
                Need Clarification
              </button>
            </div>
            <div className="chat-input">
              <input
                type="text"
                className={
                  enableChatInput
                    ? "input-glow lessonPlan_input"
                    : "lessonPlan_input"
                }
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                placeholder="Type a message..."
                disabled={!enableChatInput}
              />
              <button
                onClick={handleChatSubmit}
                disabled={!enableChatInput || !inputMessage.trim()}
              >
                Send
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LessonPlanGenerator;
