// src/countryCodes.js

const countryCodes = [
    { code: 'IN', display: '+91', name: 'India' },
    { code: 'US', display: '+1', name: 'United States' },
    { code: 'UK', display: '+44', name: 'United Kingdom' },
    // Add more country codes as needed
    { code: 'CA', display: '+1', name: 'Canada' },
    { code: 'AU', display: '+61', name: 'Australia' },
    { code: 'FR', display: '+33', name: 'France' },
    { code: 'DE', display: '+49', name: 'Germany' },
    { code: 'JP', display: '+81', name: 'Japan' },
    // ... other countries
  ];
  
  export default countryCodes;
  