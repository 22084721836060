import React from "react";
import PropTypes from "prop-types";
import "./LessonPlanModal.css";
import Markdown from "react-markdown";
import { IoClose } from "react-icons/io5";

const LessonPlanModal = ({ plan, onclickfn }) => {
  return (
    <div className="lesson-plan-modal">
      <div className="lesson-plan-modal-content">
        <IoClose className="lesson-modal-close" onClick={() => onclickfn()} />
        <Markdown className="modal-markdown" children={plan} />
      </div>
    </div>
  );
};

LessonPlanModal.propTypes = {
  plan: PropTypes.string.isRequired,
};

export default LessonPlanModal;
