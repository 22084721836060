import React from 'react';
import './shortCourse.css';

const courses = [
  { title: "Data Science", institution: "Coursera", rating: "4.8", students: "1200" },
  { title: "Intro to AI", institution: "edX", rating: "4.7", students: "950" },
  { title: "Web Dev Bootcamp", institution: "Udemy", rating: "4.9", students: "1500" },
  { title: "Machine Learning", institution: "Coursera", rating: "4.6", students: "1100" },
  { title: "Graphic Design", institution: "Skillshare", rating: "4.5", students: "800" },
  { title: "Business Analytics", institution: "edX", rating: "4.7", students: "1000" },
  { title: "Python Programming", institution: "Udemy", rating: "4.8", students: "1300" },
  { title: "Digital Marketing", institution: "Coursera", rating: "4.6", students: "950" },
  { title: "Cyber Security", institution: "edX", rating: "4.7", students: "900" },
  { title: "Cloud Computing", institution: "Udemy", rating: "4.5", students: "850" },
  { title: "Big Data Essentials", institution: "Coursera", rating: "4.6", students: "750" },
  { title: "Blockchain Technology", institution: "edX", rating: "4.8", students: "900" }
];

const CourseGrid = () => {
  return (
    <div className="shortcourse_grid">
      {courses.map((course, index) => (
        <div className="shortcourse_card" key={index}>
          <div className="shortcourse_title">{course.title}</div>
          <div className="shortcourse_institution">Institution: {course.institution}</div>
          <div className="shortcourse_info">
            <span>Rating: {course.rating} </span> | 
            <span> {course.students} students</span>
          </div>
          <button className="shortcourse_button">View Course</button>
        </div>
      ))}
    </div>
  );
};

export default CourseGrid;
