// src/components/PrivateRoute.js
// import React from "react";
// import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  // const token = localStorage.getItem("jwtToken");

  // Redirect to login if no token is found
  // return token ? children : <Navigate to="/login" replace />;
  return children;
};

export default PrivateRoute;
