// eslint-disable-next-line jsx-a11y/anchor-is-valid
// eslint-disable-next-line import/no-anonymous-default-export
import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./TeacherHeader.css";
import { FaUserCircle } from "react-icons/fa";

const TeacherHeader = () => {
  const navigate = useNavigate(); 
  const handleLogout = (event) => {
    event.preventDefault(); 
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("usertype");
    localStorage.removeItem("userData");
    localStorage.removeItem("chatSessionId");
    localStorage.removeItem("activities");
    localStorage.removeItem("SessionID_lpgen");
    localStorage.removeItem("chatMessages");
    localStorage.removeItem("SessionID_apgen");
    localStorage.removeItem("AssesmentMessage");
    localStorage.removeItem("assgenID");
    localStorage.removeItem("lpgenID");
    localStorage.removeItem("encryptedFormData");

    // Loop through all localStorage items
    for (let i = localStorage.length - 1; i >= 0; i--) {
      const key = localStorage.key(i);
      // Check if the key starts with 'messages_'
      if (key.startsWith("messages_")) {
        localStorage.removeItem(key);
      }
    }

    localStorage.removeItem("sessionHistory");
    localStorage.removeItem("sessionID");
    navigate("/"); // Redirect to home or login page
  };

  const [selectedMenu, setSelectedMenu] = useState("Dashboard"); 
  const [accountDropdownVisible, setAccountDropdownVisible] = useState(false);
  const searchRef = useRef(null);

  const handleMenuClick = (menu) => {
    setSelectedMenu(menu);
  };

  // Handle clicking outside to collapse search
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        // setSearchActive(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchRef]);

  return (
    <header className="teacher-Header">
    <div className="teacher-container">
      <div className="teacher-image">
        <img
          src="/logo.png"
          alt="AIALA Logo"
          className="logo"
          onClick={() => navigate("/TeacherHome")}
        />
      </div>
      <div className="teacher-right-section">
        <ul className="teacher-header-menu">
          {["Dashboard", "Assistant", "Assessments"].map((menu) => {
            switch (menu) {
              case "Dashboard":
                return (
                  <li
                    key={menu}
                    className={`teacher-header-menu-item ${selectedMenu === menu ? "selected" : ""
                      }`}
                    onClick={() => handleMenuClick(menu)}
                  >
                    <Link to="/TeacherHome">{menu}</Link>{" "}
                    {/* Linking to TeacherHome.js */}
                  </li>
                );
              case "Assistant":
                return (
                  <li
                    key={menu}
                    className={`teacher-header-menu-item ${selectedMenu === menu ? "selected" : ""
                      }`}
                    onClick={() => setSelectedMenu(menu)}
                  >
                    {menu}
                    <div className="teacher-dropdown-content">
                      <a href="/Study">Study Buddy</a>
                      <Link to="/LessonPlan">Lesson Planner</Link>{" "}
                      <a href="/ExpertActivityAssistant">Activity Generator</a>
                      {/* <a href="/AddStudent">Assessment Generator</a> */}
                      <a href="/chatbot">Mentor Mate</a>
                    </div>
                  </li>
                );
              case "Assessments":
                return (
                  <li
                    key={menu}
                    className={`teacher-header-menu-item ${selectedMenu === menu ? "selected" : ""
                      }`}
                    onClick={() => setSelectedMenu(menu)}
                  >
                    {menu}
                    <div className="teacher-dropdown-content">
                      <a href="/GenerateAssesment">Generate Assessment</a>
                      <Link to="/TeacherViewAssessment">View Assessment</Link>


                    </div>
                  </li>
                );
              default:
                return null;
            }
          })}
        </ul>
        <div className="teacher-search-and-account">
          <div
            className="teacher-account-dropdown"
            onClick={() => setAccountDropdownVisible(!accountDropdownVisible)}
          >
            <FaUserCircle className="_header_icon" /> Accounts
            {accountDropdownVisible && (
              <div className="teacher-dropdown-content">
                <li>
                  <Link to="/account">My Account</Link>
                </li>
                {/* eslint-disable-next-line */}
                <a onClick={handleLogout}>Logout</a>
                {/* <button className="link-style" onClick={handleLogout}>Logout</button> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
    </header>
  );
};

export default TeacherHeader;
