import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
// import Button from "../components/Button";
import { Link } from "react-router-dom";
import { loginUser } from "../api";
import { v4 as uuidv4 } from "uuid";
import LoadingAnimation from "../components/LoadingAnimation";
import CustomButton from "../components/CustomButton";
// import dotenv from 'dotenv';
import CryptoJS from 'crypto-js';

// dotenv.config();

// Login component
const Login = () => {
  // State hooks for managing form inputs and loading state
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Hook for navigating programmatically

  // Use the secret key from environment variables
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  // console.log("Secret Key:", secretKey);
  // Encrypt data function
  const encryptData = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
  };

  // Handle form submission
  const handleLogin = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    setLoading(true); // Start loading animation
    try {
      // Attempt login with email and password
      const userData = await loginUser({ email, password });
      // console.log("Login successful:", userData); // Log successful login
      setLoading(false); // Stop loading animation
      if (userData && userData.token) {
        // Store user details in localStorage
        const sessionID = uuidv4();
        localStorage.setItem("sessionID", sessionID); // Store session ID
        // localStorage.setItem("jwtToken", userData.token);
        // localStorage.setItem("userData", JSON.stringify(userData));
        localStorage.setItem("jwtToken", encryptData(userData.token));
        localStorage.setItem("userData", encryptData(userData));
        // Redirect user based on type
        navigateUser(userData.usertype);
      } else {
        // Handle cases where login is not successful
        alert("Login failed!");
      }
    } catch (error) {
      setLoading(false);
      console.error("Login failed with error:", error);

      // Determine the type of error message to display
      let errorMessage = "Login failed! Please try again later.";
      if (error.message.includes("Network")) {
        errorMessage =
          "Cannot connect to the server. Please check your internet connection.";
      } else if (error.message.includes("User not found")) {
        errorMessage =
          "No account found with the provided credentials. Please sign up.";
      } else if (error.message.includes("Invalid credentials")) {
        errorMessage = "Invalid username or password. Please try again.";
      } else if (error.message.includes("technical issue")) {
        errorMessage =
          "Login failed due to a technical issue. Please try again later.";
      }

      alert(errorMessage);
    }
  };

  // Function to navigate user based on userType
  const navigateUser = (userType) => {
    switch (userType) {
      case "teacher":
        navigate("/teacher/home");
        break;
      case "student":
        navigate("/student/home");
        break;
      case "parent":
        navigate("/parent/home");
        break;
      case "saas":
        navigate("/saas/home");
        break;
      default:
        navigate("/"); // Navigate to home page if userType is unknown
    }
  };

  // JSX to render the login form
  return (
    <div className="login-container">
      {loading && <LoadingAnimation />}
      <div className="login-box">
        <h2>Login</h2>
        <form onSubmit={handleLogin}>
          <div className="input-group">
            <label htmlFor="email">Email/Username</label>
            <input
              type="text"
              id="email"
              name="email"
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              required
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <a href="#forgot-password" className="forgot-password">
            Forgot Password?
          </a>
          <CustomButton
            text="Log in"
            className="custom_button max"
            as="button"
            type="submit"
            filled
          >
            Login
          </CustomButton>
        </form>
        <div className="login-options">
          <span>
            {/* New to AIALA? */}
            Need to create an account?
            <Link className="_links" to="/register">
              Sign Up
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Login;
