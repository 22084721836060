// src/api.js

const API_URL = "https://aiala.troshi.in/api";

export const registerUser = async (userData) => {
  try {
    const response = await fetch(`${API_URL}/users/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(userData),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const loginUser = async (loginData) => {
  try {
    const response = await fetch(`${API_URL}/users/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(loginData),
    });
    const result = await response.json();
    if (!response.ok) {
      throw new Error(
        result.detail ||
          "Login failed due to a technical issue. Please try again later."
      );
    }
    return result;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const changePassword = async (passwordData, token) => {
  try {
    const response = await fetch(`${API_URL}/users/change-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(passwordData),
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const verifyEmail = async (token) => {
  try {
    const response = await fetch(`${API_URL}/users/verify-email`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const verifyPhoneNumber = async (token) => {
  try {
    const response = await fetch(`${API_URL}/users/verify-phone`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
export const verifytoken = async (token) => {
  try {
    const response = await fetch(`${API_URL}/users/verify-token`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const fetchSubjects = async (classes, boards) => {
  try {
    const response = await fetch(`${API_URL}/users/subjects`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ classes, boards }),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data.subjects || []; // Make sure it defaults to an array if undefined
  } catch (error) {
    console.error("Error:", error);
    return []; // Return an empty array in case of an error
  }
};

export const updateUserType = async (data) => {
  try {
    const response = await fetch(`${API_URL}/users/updateUserType`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const fetchUpdatedUserDetails = async (username) => {
  try {
    const response = await fetch(`${API_URL}/users/user`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ username }),
    });
    if (!response.ok) {
      throw new Error("Failed to fetch user details");
    }
    return await response.json(); // Return the data directly
  } catch (error) {
    console.error("Failed to update user details:", error);
    throw new Error("Failed to update user details. Please log in again.");
  }
};

export const sendMessage = async (message) => {
  try {
    const response = await fetch(`${API_URL}/users/send-message`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(message),
    });
    if (!response.ok) {
      const errorText = await response.text(); // Fetch the error text from response
      console.error("API Error:", errorText);
      throw new Error(
        errorText || "Failed to send message due to a technical issue."
      );
    }
    return response; // Ensure the response is returned here
  } catch (error) {
    console.error("Error sending message:", error);
    throw error;
  }
};

export const getAllEducationalContent = async () => {
  try {
    const response = await fetch(`${API_URL}/users/getAllEducationalContent`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch grades");
    }
    return await response; // Assuming the endpoint returns an array of grades
  } catch (error) {
    console.error("Error fetching grades:", error);
    throw error;
  }
};

export const getAssesmentResults = async (username) => {
  try {
    const response = await fetch(`${API_URL}/results/getAssessmentDetails`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username }), // Include the username in the request body
    });
    if (!response.ok) {
      const errorDetails = await response.json(); // Optionally fetch more details about the error
      throw new Error(`Failed to fetch assessment results: ${errorDetails.message || ''}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching assessment results:', error);
    throw error;
  }
};

export const generatelessonplan = async (lessonPlanDetails) => {
  try {
    const response = await fetch(`${API_URL}/users/generatelessonplan`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(lessonPlanDetails),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const updateLessonPlan = async (LessonPlanUpdates) => {
  try {
    const response = await fetch(`${API_URL}/users/updateLessonPlan`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(LessonPlanUpdates),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getAllLessonPlans = async (type, option, username) => {
  try {
    const response = await fetch(
      `${API_URL}/users/getAllLessonPlans?type=${type}&option=${option}&username=${username}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Failed to fetch grades: ${response.statusText}`);
    }
    const text = await response.text(); // Use .text() to handle non-JSON responses gracefully
    return text ? JSON.parse(text) : {}; // Only parse if text is not empty
  } catch (error) {
    console.error("Error fetching grades:", error);
    throw error;
  }
};

export const generateActivity = async (ActivityDetails) => {
  try {
    const response = await fetch(`${API_URL}/users/generateActivity`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(ActivityDetails),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const updateActivity = async (ActivityUpdates) => {
  try {
    const response = await fetch(`${API_URL}/users/updateActivity`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(ActivityUpdates),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getAllactivity = async () => {
  try {
    const response = await fetch(`${API_URL}/users/getAllactivity`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch grades");
    }
    return await response.json(); // Assuming the endpoint returns an array of grades
  } catch (error) {
    console.error("Error fetching grades:", error);
    throw error;
  }
};

export const AssesmentRequest = async (AssesmentRequest) => {
  try {
    const response = await fetch(`${API_URL}/users/AssesmentRequest`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(AssesmentRequest),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const updateAssessment = async (updateAssessment) => {
  try {
    const response = await fetch(`${API_URL}/users/updateAssessment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updateAssessment),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const publishAssessment = async (publishAssessment) => {
  try {
    const response = await fetch(`${API_URL}/users/publishAssessment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(publishAssessment),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const studentAssessment = async (studentAssessment) => {
  try {
    const response = await fetch(`${API_URL}/users/studentAssessment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(studentAssessment),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getAllTeacherAssessments = async (username) => {
  try {
    const response = await fetch(
      `${API_URL}/users/getAllTeacherAssessments?username=${username}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Failed to fetch grades: ${response.statusText}`);
    }
    const text = await response.text(); // Use .text() to handle non-JSON responses gracefully
    return text ? JSON.parse(text) : {}; // Only parse if text is not empty
  } catch (error) {
    console.error("Error fetching grades:", error);
    throw error;
  }
};

export const getAllStudentAssessments = async (username) => {
  try {
    const response = await fetch(
      `${API_URL}/users/getAllStudentAssessments?username=${username}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Failed to fetch grades: ${response.statusText}`);
    }
    const text = await response.text(); // Use .text() to handle non-JSON responses gracefully
    return text ? JSON.parse(text) : {}; // Only parse if text is not empty
  } catch (error) {
    console.error("Error fetching grades:", error);
    throw error;
  }
};

export const getGradesInstitutes = async (username) => {
  const url = `${API_URL}/users/getGradesInstitutes?teacher_username=${username}`;
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

export const addStudent = async (studentData, username) => {
  try {
    const payload = {
      studentData,
      username,
    };
    const response = await fetch(`${API_URL}/users/AddStudent`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getAllSchools = async () => {
  try {
    const response = await fetch(`${API_URL}/users/getAllSchools`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch Schools");
    }
    return await response.json(); // Assuming the endpoint returns an array of grades
  } catch (error) {
    console.error("Error fetching Schools:", error);
    throw error;
  }
};

export const getAllBoards = async () => {
  try {
    const response = await fetch(`${API_URL}/users/getAllBoards`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch Boards");
    }
    return await response.json(); // Assuming the endpoint returns an array of grades
  } catch (error) {
    console.error("Error fetching Boards:", error);
    throw error;
  }
};

export const getStudentAssessment = async (assessment_id) => {
  try {
    const response = await fetch(
      `${API_URL}/users/getStudentAssessment?id=${assessment_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Failed to fetch assessment: ${response.statusText}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching assessment:", error);
    throw error;
  }
};

export const submitAnswers = async (submitAnswers) => {
  try {
    const response = await fetch(`${API_URL}/users/submitAnswers`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(submitAnswers),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const student_assessment_report = async (assessment_id) => {
  try {
    const response = await fetch(
      `${API_URL}/users/student_assessment_report?assessment_id=${assessment_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Failed to fetch assessment: ${response.statusText}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching assessment:", error);
    throw error;
  }
};

export const getDocument = async (formData) => {
  try {
    const response = await fetch(`${API_URL}/users/getDocument`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data; // Assuming the server returns the URL in a JSON object
  } catch (error) {
    console.error("Failed to fetch document:", error);
    return null; // Handle errors or return a default value
  }
};

export const getAllUserDocumentURL = async (formData) => {
  try {
    const response = await fetch(`${API_URL}/document/getAllUserDocumentURL`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data; // Assuming the server returns the URL in a JSON object
  } catch (error) {
    console.error("Failed to fetch document:", error);
    return null; // Handle errors or return a default value
  }
};

export const studentChatbot = async (data) => {
  try {
    const requestBody = {
      message: data.message,
      grade: data.class, // Adjust this if 'grade' should be different from 'class'
      board: data.board,
      subject: data.subject,
      chapter: data.chapterNumber.toString(), // Assuming chapter number is a number and needs to be sent as a string
      username: data.username,
      sessionID: data.sessionID,
      chatID: data.chatID,
      sender: data.sender,
      firstname: data.firstname,
      usertype: data.usertype,
      email: data.email,
      timestamp: data.timestamp || new Date().toISOString()
    };

    const response = await fetch(`${API_URL}/users/studybuddy`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      const errorText = await response.text(); // Fetch the error text from the response
      console.error("API Error:", errorText);
      throw new Error(`Failed to send message with status code: ${response.status}`);
    }

    const responseData = await response.json(); // Assuming the server sends back JSON
    return responseData;

  } catch (error) {
    console.error("Failed to communicate with the study buddy API:", error);
    throw error; // Rethrow the error to handle it where the function is called
  }
};

export const shortcourse = async (training_id) => {
  try {
    const response = await fetch(
      `${API_URL}/users/shortcourse?training_id=${training_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Failed to fetch assessment: ${response.statusText}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching assessment:", error);
    throw error;
  }
};

export const generateCourse = async (topic, username) => {
  const formData = {
    message: topic,
    username: username,
  };

  try {
    const response = await fetch(`${API_URL}/course/generateCourse`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      const errorBody = await response.text(); // Attempt to read response text for additional info
      throw new Error(
        `HTTP error! Status: ${response.status}, Body: ${errorBody}`
      );
    }

    return await response.json();
  } catch (error) {
    console.error("Failed to generate course:", error);
    throw error; // Make sure to throw the error to handle it in the calling function
  }
};

export const getCourse = async (username) => {
  const url = `${API_URL}/users/getCourse?username=${username}`;
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

export const getAllUserContent = async (username) => {
  const url = `${API_URL}/document/getAllUserContent?username=${username}`;
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

export const uploadDocument = async (formData, username) => {
  const url = `${API_URL}/document/uploadDocument`;
  try {
    const response = await fetch(url, {
      method: "POST",
      body: formData,
      // Note: We don't set the Content-Type header here because the browser will
      // automatically set the correct boundary for multipart/form-data
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Upload error:", error);
    throw error;
  }
};
